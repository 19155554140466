<template>
  <div class="card w-100" style="height: 380px">
    <div class="card-header pb-75">
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center"
      >
        <h4 class="mb-0 text-center">
          Implementation Of Annexture Control Requirements By Domain
        </h4>
      </div>
    </div>
    <div class="card-body pt-0" style="overflow: auto">
      <card-percentage-table :listData="listData" :chartSeries="chartSeries" />
    </div>
  </div>
</template>

<script>
import CardPercentageTable from "../../../components/CardPercentageTable.vue";
export default {
  components: { CardPercentageTable },
  props:{
    clausesData:{
      type:Array,
      required:false
    },
    keys:{
      type:Array,
      required:true
    }
  },
  computed:{
    chartSeries(){
      if(this.clausesData&&Array.isArray(this.clausesData))
      return this.clausesData.map(item=>item.compliance)
    else return []
    },
    listData(){
      if(this.clausesData&&Array.isArray(this.clausesData))
      return this.clausesData.map(item=>{
        return {
          name:item.title,
          usage:item.compliance
        }
        
      })
      else return []
    }
  },
  // data() {
  //   return {
  //     chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
  //     listData: [
  //       {
  //         name: "Google Chrome",
  //         usage: "54.4%",
  //       },
  //       {
  //         name: "Mozila Firefox",
  //         usage: "6.1%",
  //       },
  //       {
  //         name: "Apple Safari",
  //         usage: "14.6%",
  //       },
  //       {
  //         name: "Internet Explorer",
  //         usage: "4.2%",
  //       },
  //       {
  //         name: "Opera Mini",
  //         usage: "8.4%",
  //       },
  //     ],
  //   };
  // },
};
</script>

<style></style>
