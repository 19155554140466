<template>
    <div class="card w-100" style="height: 380px">
      <div class="card-header pb-75">
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center"
        >
          <h4 class="mb-0 text-center">HIE Control Summary</h4>
        </div>
      </div>
      <div class="card-body pt-0" style="overflow:auto">
        <card-percentage-table :listData="listData" :chartSeries="chartSeries" />
      </div>
    </div>
  </template>
  
  <script>
  import CardPercentageTable from "../../../components/CardPercentageTable.vue";
  export default {
    components: { CardPercentageTable },
    props:{
      summaryData:{
        type:Object,
        required:false
      }
    },
    computed:{
        chartSeries(){
            return  [this.summaryData[0].compliance,100-this.summaryData[0].compliance]
        },
        listData(){

           return [
              {
                name: "Overall Control Implementation",
                usage: this.summaryData[0].compliance,
              },
              {
                name: "Not Applicable Control",
                usage: 100-this.summaryData[0].compliance,
              },
             
            ]
        } 
    },
    data() {
      return {
        chartSeries: [summaryData[0].compliance,100-summaryData[0].compliance],
        listData: [
          {
            name: "Overall Control Implementation",
            usage: summaryData[0].compliance,
          },
          {
            name: "Not Applicable Control",
            usage: 100-summaryData[0].compliance,
          },
         
        ],
      };
    },

  };
  </script>
  
  <style></style>
  