<template>
    <div class="card w-100 implementation-of-control-by-domain" style="height: 380px">
      <div class="card-header pb-75">
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center"
        >
          <h4 class="mb-0 text-center">
            Implementation Of Control By Domain
            <!-- {{ listData }} -->
          </h4>
        </div>
      </div>
      <div class="card-body implemnetation-wrapper pt-0" style="overflow: auto">
        <card-percentage-table :listData="listData" :chartSeries="chartSeries" />
      </div>
    </div>
  </template>
  
  <script>
  import CardPercentageTable from "../../../components/CardPercentageTable.vue";
  export default {
    components: { CardPercentageTable },
    props:{
      clausesData:{
        type:Array,
        required:false
      },
      keys:{
        type:Array,
        required:true
      }
    },
    computed:{
      chartSeries(){
        if(this.clausesData&&Array.isArray(this.clausesData))
        if(this.keys?.length)
        return this.clausesData.filter(item=>this.keys.includes(item.title)).map(item=>item.compliance)
        else
        return this.clausesData.map(item=>item.compliance)
      else return []
      },
      listData(){
        if(this.clausesData&&Array.isArray(this.clausesData))
        if(this.keys?.length)
        return this.clausesData.filter(item=>this.keys.includes(item.title)).map(item=>{
          return {
            name:item.title,
            usage:item.compliance
          }
          
        })
        else 
        return this.clausesData.map(item=>{
          return {
            name:item.title,
            usage:item.compliance
          }
          
        })
        else return []
      }
    },
    data() {
      return {
        // chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
        // listData: [
        //   {
        //     name: "Google Chrome",
        //     usage: "54.4%",
        //   },
        //   {
        //     name: "Mozila Firefox",
        //     usage: "6.1%",
        //   },
        //   {
        //     name: "Apple Safari",
        //     usage: "14.6%",
        //   },
        //   {
        //     name: "Internet Explorer",
        //     usage: "4.2%",
        //   },
        //   {
        //     name: "Opera Mini",
        //     usage: "8.4%",
        //   },
        // ],
      };
    },
  };
  </script>
  
  <style lang="scss">
.implementation-of-control-by-domain{

  .implemnetation-wrapper::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
  }
  .implemnetation-wrapper{
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
}
</style>
  