<template>
  <div class="w-100 h-auto">
    <b-sidebar
      id="evidence-bulk-uploadsidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
    >
      <evidence-bulk-upload-section
        :closeAction="closeBulkUploadSidebar"
        :reloadParent="load"
        :gapAssessmentId="gap._id"
        :standardId="gap.standard._id"
      />
    </b-sidebar>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="card">
        <div
          class="card-header d-flex flex-column align-items-start justify-content-start"
        >
          <div class="w-100 d-flex align-items-start justify-content-between">
            <div
              class="d-flex flex-column align-items-start justify-content-start"
              style="max-width: 60%"
            >
              <h2 class="font-weight-bold">
                {{ gap.title }}
              </h2>
              <p class="mb-0">
                {{ gap.notes ? gap.notes : "" }}
              </p>
            </div>

            <div
              v-if="gap.standard && gap.standard != null"
              class="d-flex flex-column align-items-end justify-content-start cursor-pointer"
              @click="
                () => {
                  $router.push(`/standards/${gap.standard._id}/details`);
                }
              "
            >
              <h3 class="font-weight-bold">{{ gap.standard.name }}</h3>
              <b-badge pill variant="primary"
                >Standard <feather-icon class="ml-25" icon="ExternalLinkIcon"
              /></b-badge>
            </div>
          </div>
          <div
            class="w-100 d-flex align-items-center justify-content-start mt-1"
          >
            <gap-overall-progress-bar
              class="mr-1"
              :value="gap.completion || 0"
              title="Overall Progress"
              :colorsReversed="true"
            />
            <gap-overall-progress-bar
              :value="gap.gap || 0"
              title="Overall Gap"
            />
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header pb-75">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <h3 class="mb-25 font-weight-bolder">Assessment</h3>
              <p class="mb-0">Assess your controls.</p>
            </div>
            <div
              class="d-flex flex-column align-items-center justify-content-between"
            >
              <b-button @click="openBulkUploadSidebar" size="sm" variant="info"
                ><feather-icon icon="UploadIcon" class="mr-50" />Bulk Upload
                Evidence</b-button
              >
            </div>
          </div>
        </div>

        <!-- <div class="col mt-75 py-1 rounded border">
          <b-row class="d-flex justify-content-between mb-2">
            <h4 class="mx-2 text-dark font-weight-bold">
              Filters
              <feather-icon class="ml-55" size="20" icon="FilterIcon" />
            </h4>
            <b-button
              variant="outline-danger"
              class="mx-2"
              size="sm"
              @click="clearFilters"
              ><feather-icon icon="XIcon" class="mr-50" />
              <span class="align-middle">Clear Filters</span></b-button
            >
          </b-row>
          <b-row>
            <div class="col-sm-12">
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <b-form-group style="width: 31%">
                  <v-select
                    v-model="mainFilters.response_value"
                    label="text"
                    :reduce="(opt) => opt.value"
                    :options="responseOptions"
                  />
                </b-form-group>

                <b-form-group style="width: 31%">
                  <v-select
                    v-model="mainFilters.evidence_status"
                    label="text"
                    :reduce="(opt) => opt.value"
                    :options="evidenceOptions"
                  />
                </b-form-group>

                <b-form-group style="width: 31%">
                  <v-select
                    v-model="mainFilters.tag"
                    label="text"
                    :reduce="(opt) => opt.value"
                    :options="tagOptions"
                  />
                </b-form-group>
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="app-fixed-search col-sm-12">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    v-if="
                      mainFilters.search == null || mainFilters.search == ''
                    "
                    icon="SearchIcon"
                    class="text-muted"
                  />
                  <feather-icon
                    @click="
                      () => {
                        mainFilters.search = null;
                      }
                    "
                    v-else
                    icon="XIcon"
                    class="text-danger cursor-pointer"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="mainFilters.search"
                  placeholder="Search Name, Control number ..."
                  debounce="500"
                  size="md"
                />
              </b-input-group>
            </div>
          </b-row>
        </div> -->
        <assessment-controls
          v-if="gap.standard && gap.standard != null"
          :mainFilters="mainFilters"
          :controlFamilies="gap.standard.control_families"
          :responseSet="gap.standard.response_set"
          :gapTitle="gap.title"
          :standardName="gap.standard.name"
          :gapUserResourcePermissions="gap.user_resource_permissions"
          :standardUpdateDate="gap.standard.updated_at"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import ResponseMixins from "@/mixins/ResponseMixins";
import GapAssessmentMixins from "@/mixins/GapAssessmentMixins";
import {
  BOverlay,
  BButton,
  BBadge,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BRow,
  BCol,
  BFormGroup,
  BSidebar,
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import GapOverallProgressBar from "./components/GapOverallProgressBar.vue";
import AssessmentControls from "./sections/AssessmentControls/AssessmentControls.vue";
import vSelect from "vue-select";
import EvidenceBulkUploadSection from "./sections/EvidenceBulkUploadSection.vue";
export default {
  mixins: [ResponseMixins, GapAssessmentMixins],
  components: {
    BOverlay,
    BButton,
    BBadge,
    FeatherIcon,
    GapOverallProgressBar,
    AssessmentControls,
    vSelect,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BRow,
    BCol,
    BFormGroup,
    BSidebar,
    EvidenceBulkUploadSection,
  },
  data() {
    return {
      showOverlay: false,
      gap: {},

      mainFilters: {
        response_value: null,
        evidence_status: null,
        tag: null,
        search: null,
      },

      responseOptions: [
        { text: "--Filter By Response Type--", value: null },
        { text: "Not Applicable", value: -1 },
        { text: "No Response", value: -2 },
      ],
      evidenceOptions: [
        { text: "--Filter By Evidence Status--", value: null },
        { text: "No Evidence", value: 0 },
        { text: "Evidence Requested", value: 1 },
        { text: "Uploaded", value: 2 },
        { text: "Evidence Approved", value: 3 },
        { text: "Rejected", value: 4 },
      ],
      tagOptions: [{ text: "--Filter By Tag--", value: null }],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getGapAssessmentDetailsAndSetData(this.$route.params.id);
    },

    getGapAssessmentDetailsAndSetData(id) {
      this.showOverlay = true;
      this.getGapAssessmentDetails(id)
        .then((res) => {
          this.gap = res.data.data;
          if (
            res.data.data.tags &&
            res.data.data.tags != null &&
            res.data.data.tags.length > 0
          ) {
            this.tagOptions = [
              { text: "--Filter By Tag--", value: null },
              ...res.data.data.tags.map((tag) => {
                return { text: tag, value: tag };
              }),
            ];
          }
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    openBulkUploadSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "evidence-bulk-uploadsidebar");
    },

    closeBulkUploadSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "evidence-bulk-uploadsidebar");
    },

    clearFilters() {
      this.mainFilters = {
        response_value: null,
        evidence_status: null,
        tag: null,
        search: null,
      };
    },
  },
};
</script>

<style></style>
