<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <sidebar-template
      title="Bulk Upload Evidence Files"
      :closeAction="closeAction"
    >
      <template #content>
        <div class="w-100 h-auto">
          <b-modal
            id="file_upload_modal"
            ref="file_upload_modal"
            hide-footer
            title="Bulk Upload Evidences"
            size="lg"
          >
            <p class="text-center text-warning">
              The following fields are not compulsary, You can click Upload to
              finish the process.
            </p>
            <validation-observer ref="UploadForm" #default="{ invalid }">
              <b-form class="mt-2" @submit.prevent="handleFileUpload">
                <b-form-group label="Description">
                  <validation-provider #default="{ errors }" name="description">
                    <b-form-input
                      id="description"
                      v-model="modal.description"
                      :state="errors.length > 0 ? false : null"
                      name="description"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- 
              <b-form-group label="Control">
                <validation-provider #default="{ errors }" name="control">
                  <b-form-select
                    v-model="modal.controlId"
                    :options="controlOptions"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

                <b-form-group label="Link Control">
                  <controlTree :cf="standard.control_families" @val="getVal" />
                </b-form-group>

                <b-button type="submit" variant="success" :disabled="invalid">
                  <feather-icon icon="UploadIcon" class="mr-50" />
                  Upload
                </b-button>
              </b-form>
            </validation-observer>
          </b-modal>

          <b-modal
            id="edit_evidence_details_modal"
            ref="edit_evidence_details_modal"
            hide-footer
            title="Edit Evidence Details"
            size="lg"
          >
            <div class="p-0">
              <validation-observer ref="UploadForm" #default="{ invalid }">
                <b-form class="mt-0" @submit.prevent="updateBulkUpload">
                  <b-form-group label="Description">
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                    >
                      <b-form-input
                        id="description"
                        v-model="update_description"
                        :state="errors.length > 0 ? false : null"
                        name="description"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Link Control">
                    <controlTree
                      :cf="standard.control_families"
                      @val="getVal"
                      :selectedcontrol="controlids"
                    />
                  </b-form-group>

                  <b-button type="submit" variant="success" :disabled="invalid">
                    Update
                  </b-button>
                </b-form>
              </validation-observer>
            </div>
          </b-modal>

          <b-sidebar
            ref="sidebar-bulkupload"
            lazy
            id="sidebar-bulkupload"
            sidebar-class="sidebar-lg"
            bg-variant="white"
            backdrop
            right
            no-header
            shadow
            v-model="sidebar_bulkupload"
            class="p-1"
          >
            <div class="p-2">
              <validation-observer ref="UploadForm" #default="{ invalid }">
                <b-form class="mt-2" @submit.prevent="handleFileUpload">
                  <b-form-group label="Description">
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                    >
                      <b-form-input
                        id="description"
                        v-model="modal.description"
                        :state="errors.length > 0 ? false : null"
                        name="description"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Link Control">
                    <controlTree
                      :cf="standard.control_families"
                      @val="getVal"
                    />
                  </b-form-group>

                  <b-button type="submit" variant="success" :disabled="invalid">
                    <feather-icon icon="UploadIcon" class="mr-50" />
                    Upload
                  </b-button>
                </b-form>
              </validation-observer>
            </div>
          </b-sidebar>
          <!-- <b-sidebar
          ref="sidebar-bulkupdate"
          lazy
          id="sidebar-bulkupdate"
          sidebar-class="sidebar-lg"
          bg-variant="white"
          backdrop
          right
          no-header
          shadow
          v-model="sidebar_bulkupdate"
          class="p-1"
        >
          <div class="p-2">
            <validation-observer ref="UploadForm" #default="{ invalid }">
              <b-form class="mt-2" @submit.prevent="updateBulkUpload">
                <b-form-group label="Description">
                  <validation-provider #default="{ errors }" name="description">
                    <b-form-input
                      id="description"
                      v-model="update_description"
                      :state="errors.length > 0 ? false : null"
                      name="description"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Link Control">
                  <controlTree
                    :cf="standard.control_families"
                    @val="getVal"
                    :selectedcontrol="controlids"
                  />
                </b-form-group>

                <b-button type="submit" variant="success" :disabled="invalid">
                  Update
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </b-sidebar> -->

          <div class="d-flex align-items-center justify-content-center py-0">
            <p class="text-center text-warning mb-0">
              Upload documents and attachments for assessment
            </p>
          </div>

          <div class="">
            <div
              style="height: 180px; border: 2px dotted #4b4b4b"
              class="rounded w-100 mt-1 d-flex justify-content-center align-items-center"
            >
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <feather-icon
                  icon="UploadCloudIcon"
                  class="text-primary"
                  size="24"
                />
                <input
                  type="file"
                  multiple
                  :name="uploadFieldName"
                  :disabled="isSaving"
                  @change="
                    filesChange($event.target.name, $event.target.files);
                    fileCount = $event.target.files.length;
                  "
                  accept="*"
                  class="input-file"
                />
                <p class="drag-area-text" v-if="isInitial">
                  Drag your file(s) here to begin<br />
                  or click to browse
                </p>
                <p class="drag-area-text" v-if="isSaving">
                  Uploading {{ fileCount }} files...
                </p>
              </div>
            </div>

            <b-tabs class="mt-1" justified>
              <b-tab title="Bulk Uploaded Evidences">
                <table role="table" class="table table-sm">
                  <thead role="rowgroup">
                    <tr role="row">
                      <th role="columnheader" scope="col">Control</th>
                      <th role="columnheader" scope="col">File</th>
                      <!-- <th role="columnheader" scope="col">Description</th> -->
                      <th role="columnheader" scope="col">Uploaded By</th>
                      <th role="columnheader" scope="col" class="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <template
                      v-if="
                        bulkUploadedEvidences &&
                        bulkUploadedEvidences != null &&
                        bulkUploadedEvidences.length > 0
                      "
                    >
                      <tr
                        :key="evidence.file_id"
                        v-for="evidence in bulkUploadedEvidences"
                      >
                        <td role="cell">
                          <div
                            class="w-100 d-flex flex-column align-items-start justify-content-start"
                            v-for="control in evidence.controls"
                            :key="control._id"
                          >
                            <b-badge variant="light-primary" class="my-25">{{
                              control.control_no
                            }}</b-badge>
                          </div>
                        </td>

                        <td role="cell" style="max-width: 13vw">
                          <div
                            class="d-flex justify-content-start w-100 position-relative"
                          >
                            <b-badge
                              variant="light-primary"
                              style="position: relative; overflow: hidden"
                              class="d-flex flex-direction-column align-items-center justify-content-between my-0 cursor-pointer"
                              v-b-tooltip.hover
                              :title="
                                'Download: ' + evidence.original_file_name
                              "
                              @click="
                                handleDownloadButtonClick(
                                  evidence.original_file_name,
                                  evidence.file_id
                                )
                              "
                            >
                              <div
                                style="width: 27px"
                                class="h-100 d-flex align-items-center justify-content-center"
                              >
                                <feather-icon
                                  v-if="
                                    !downloadingList.includes(evidence.file_id)
                                  "
                                  icon="DownloadIcon"
                                  class="mr-75"
                                  size="27"
                                />
                                <b-spinner
                                  v-else
                                  class="ml-0 mr-75"
                                  variant="primary"
                                  small
                                />
                              </div>
                              <p class="truncate text-left mb-0">
                                {{ evidence.original_file_name }}
                              </p>
                            </b-badge>
                          </div>
                          <template
                            v-if="
                              evidence.description &&
                              evidence.description != null
                            "
                          >
                            <p class="small font-weight-bold mb-0 mt-50">
                              Description:
                            </p>
                            <small class="">
                              {{ evidence.description }}
                            </small>
                          </template>
                        </td>

                        <!-- <td role="cell" style="padding-right: 0; width: 300px">
                        <div
                          style="width: 300px"
                          class="d-flex justify-content-start"
                        >
                          <p class="font-weight-normal mb-0">
                            {{ evidence.description }}
                          </p>
                        </div>
                      </td> -->

                        <td
                          aria-colindex="2"
                          role="cell"
                          style="padding-right: 0"
                        >
                          <p
                            v-if="
                              evidence.uploaded_user &&
                              evidence.uploaded_user !== null
                            "
                            class="mb-0"
                          >
                            {{ evidence.uploaded_user.firstname }}
                            {{ evidence.uploaded_user.lastname }}
                          </p>
                          <b-badge v-else variant="danger"
                            >Deleted User</b-badge
                          >
                        </td>

                        <td>
                          <div
                            class="w-100 d-flex align-items-center justify-content-center"
                          >
                            <b-button
                              @click="openEditBulkUpload(evidence)"
                              variant="info"
                              size="sm"
                              pill
                            >
                              <span class="align-middle">
                                <feather-icon icon="Edit2Icon" />
                                <span class="ml-25 align-middle"
                                  >Edit</span
                                ></span
                              >
                            </b-button>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <empty-table-section title="No Evidences">
                        <template #content>
                          <p class="text-center">
                            It seems like there is no data at the moment.
                          </p>
                        </template>
                      </empty-table-section>
                    </template>
                  </tbody>
                </table>
              </b-tab>
              <b-tab title="Uploaded Evidence Files">
                <table role="table" class="table table-sm">
                  <thead role="rowgroup">
                    <tr role="row">
                      <th role="columnheader" scope="col">Control No.</th>
                      <th role="columnheader" scope="col">File Name</th>
                      <th role="columnheader" scope="col">Uploaded By</th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <template
                      v-if="
                        getAllfiles &&
                        getAllfiles != null &&
                        getAllfiles.length > 0
                      "
                    >
                      <tr v-for="file in getAllfiles" :key="file.file_id">
                        <td>
                          <span
                            v-if="file.subcontrol && file.subcontrol != null"
                          >
                            <span :key="file.subcontrol._id">
                              <b-badge variant="light-primary">{{
                                file.subcontrol.control_no
                              }}</b-badge></span
                            >
                          </span>
                          <span v-else>
                            <span :key="file.control._id"
                              ><b-badge variant="light-primary">{{
                                file.control.control_no
                              }}</b-badge></span
                            >
                          </span>
                        </td>
                        <td role="cell" style="padding-left: 0; width: 300px">
                          <div
                            class="d-flex justify-content-start"
                            style="min-width: 300px"
                          >
                            <b-badge
                              variant="light-primary"
                              style="position: relative; overflow: hidden"
                              class="d-flex flex-direction-column align-items-center justify-content-between cursor-pointer"
                              v-b-tooltip.hover
                              :title="'Download: ' + file.original_file_name"
                              @click="
                                handleDownloadButtonClick(
                                  file.original_file_name,
                                  file.file_id
                                )
                              "
                            >
                              <div
                                style="width: 27px"
                                class="h-100 d-flex align-items-center justify-content-center"
                              >
                                <feather-icon
                                  v-if="!downloadingList.includes(file.file_id)"
                                  icon="DownloadIcon"
                                  class="mr-75"
                                  size="27"
                                />
                                <b-spinner
                                  v-else
                                  class="ml-0 mr-75"
                                  variant="primary"
                                  small
                                />
                              </div>
                              <p class="truncate text-left mb-0">
                                {{ file.original_file_name }}
                              </p>
                            </b-badge>
                          </div>
                        </td>

                        <td role="cell" style="max-width: 12vw">
                          <p
                            v-if="
                              file.uploaded_user && file.uploaded_user !== null
                            "
                            class="mb-0"
                          >
                            {{ file.uploaded_user.firstname }}
                            {{ file.uploaded_user.lastname }}
                          </p>
                          <b-badge v-else variant="danger"
                            >Deleted User</b-badge
                          >
                        </td>
                      </tr>
                    </template>

                    <template v-else>
                      <empty-table-section title="No Evidences">
                        <template #content>
                          <p class="text-center">
                            It seems like there is no data at the moment.
                          </p>
                        </template>
                      </empty-table-section>
                    </template>
                  </tbody>
                </table>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </template>
    </sidebar-template>
  </b-overlay>
</template>

<script>
import EmptyTableSection from "../../../../../../components/EmptyTableSection";
import {
  BFormFile,
  BBadge,
  BSpinner,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BSidebar,
  BFormCheckbox,
  BTabs,
  BTab,
  VBTooltip,
  BOverlay,
} from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import TaskMixins from "@/mixins/TaskMixins";
import UtilsMixins from "@/mixins/UtilsMixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import ControlTree from "@/views/GapAssessment/components/controlTree.vue";
import SidebarTemplate from "@/components/SidebarTemplate.vue";
import GapAssessmentMixins from "@/mixins/GapAssessmentMixins";
import StandardMixins from "@/mixins/StandardMixins";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  components: {
    EmptyTableSection,
    BFormFile,
    BBadge,
    BSpinner,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BFormCheckbox,
    BTabs,
    BTab,
    vSelect,
    ControlTree,
    SidebarTemplate,
    BOverlay,
  },
  mixins: [
    ResponseMixins,
    TaskMixins,
    UtilsMixins,
    GapAssessmentMixins,
    StandardMixins,
  ],
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      modal: {
        description: null,
        controlId: null,
      },
      controlOptions: [{ text: "--Select Control--", value: null }],
      selectedFile: null,
      uploadedFiles: [],
      downloadingList: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "evidences",
      sidebar_bulkupload: false,
      controlids: [],
      sidebar_bulkupdate: false,
      update_description: "",
      fileid: "",
      getAllfiles: [],
      searchcontrol: "",
      bulkUploadedEvidences: [],
      standard: {},
      showOverlay: false,
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    filteredList() {
      return this.standard.control_families.filter((cf) => {
        return cf.name.toLowerCase().includes(this.searchcontrol.toLowerCase());
      });
    },
  },
  props: {
    reloadParent: {
      type: Function,
      required: true,
    },

    gapAssessmentId: {
      type: String,
      required: true,
    },

    closeAction: {
      type: Function,
      required: true,
    },

    standardId: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.reset();
      this.getAllevidenceFiles();
      this.getStandardDetailsAndSetData(this.standardId);
    },

    getStandardDetailsAndSetData(id) {
      this.showOverlay = true;
      this.getStandardDetails(id)
        .then((res) => {
          this.standard = res.data.data;
          console.log("Standard", this.standard);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getGapAssessmentEvidenceFilesAndSetData(gapAssessmentId) {
      this.getGapAssessmentEvidenceFiles(gapAssessmentId, { limit: 100 })
        .then((res) => {
          this.bulkUploadedEvidences = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    getVal(value) {
      // console.log(value);
      this.controlids = value;
      console.log(this.control_ids);
    },
    getAllevidenceFiles() {
      const options = {
        method: "GET",
        url:
          process.env.VUE_APP_BASEURL +
          `/gap-assessments/${this.gapAssessmentId}/all-evidences`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.data) {
            this.getAllfiles = res.data.data;
            console.log("Get All Files", this.getAllfiles);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateBulkUpload() {
      const data = {
        description: this.update_description,
        control_ids: this.controlids,
        uploaded_file_id: this.fileid,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/gap-assessments/${this.gapAssessmentId}/evidence-bulk-upload-meta-update`,
      };
      this.$http(options)
        .then((res) => {
          this.sidebar_bulkupdate = false;
          this.$refs["edit_evidence_details_modal"].hide();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openEditBulkUpload(evidence) {
      this.$refs["edit_evidence_details_modal"].show();
      console.log(evidence);
      this.update_description = evidence.description;
      this.controlids = evidence.control_ids;
      // this.sidebar_bulkupdate = true;
      this.fileid = evidence.file_id;
    },

    reset() {
      this.getGapAssessmentEvidenceFilesAndSetData(this.gapAssessmentId);
      // this.setControlOptions();
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },

    handleFileUpload() {
      this.save(this.selectedFile);
    },

    setControlOptions() {
      this.standard.map((control) => {
        this.controlOptions.push({ text: control.name, value: control._id });
      });
    },

    save(files) {
      this.currentStatus = STATUS_SAVING;
      this.gapAssessmentEvidenceBulkUpload({
        gapAssessmentId: this.gapAssessmentId,
        evidenceFiles: files,
        description: this.modal.description,
        control_ids: this.controlids,
      })
        .then((res) => {
          this.handleResponse(res);
          this.currentStatus = STATUS_INITIAL;
          this.getGapAssessmentEvidenceFilesAndSetData(this.gapAssessmentId);
        })
        .catch((err) => {
          this.handleError(err);
          this.uploadError = err.response;
          this.currentStatus = STATUS_INITIAL;
        })
        .finally(() => {
          this.selectedFile = null;
          this.modal.description = null;
          this.modal.controlId = null;
          this.controlOptions = [{ text: "--Select Control--", value: null }];
          this.hideModal();
        });
    },
    showModal() {
      this.$refs["file_upload_modal"].show();
      // this.sidebar_bulkupload = true;
      // this.controlids = [];
    },
    hideModal() {
      this.$refs["file_upload_modal"].hide();
      // this.sidebar_bulkupload = false;
    },

    filesChange(fieldName, fileList) {
      if (!fileList.length) return;
      this.showModal();
      this.selectedFile = fileList;
      // this.save(fileList);
    },

    handleDownloadButtonClick(fileName, id) {
      if (!this.downloadingList.includes(id)) {
        this.downloadingList.push(id);
        const options = {
          method: "GET",
          responseType: "blob",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/file-uploads/${id}/download`,
        };
        this.debounceFunction(() => {
          this.$http(options)
            .then((res) => {
              var blob = new Blob([res.data]);
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, fileName);
              } else {
                var a = window.document.createElement("a");
                a.href = window.URL.createObjectURL(blob, {
                  type: "text/plain",
                });
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }

              this.downloadingList = this.downloadingList.filter(
                (item_id) => item_id !== id
              );
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-custom-scrollbar {
  position: relative;
  height: 60vh;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
.drag-area-text {
  text-align: center;
  padding: 16px 0;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}
.card-out-dashed {
  border-style: dashed;
}
</style>

<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
</style>
