<template>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="dark"
      class="w-100"
    >
      <div class="card w-100" style="height: 380px">
        <div
          class="card-header d-flex flex-column align-items-center justify-content-center pb-0"
        >
          <h4 class="mb-0">
            Implementation By Control Category
          </h4>
        </div>
  
        <div
          class="card-body d-flex align-items-center justify-content-center w-100"
          
        >
          <vertical-bar-chart
            style="width: 100%;"
            :optionData="options"
            @chart-click="handleChartClick"
            :yAxisMax="100"
            v-if="!isEmpty"
          />
          <div
            v-else
            class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
          >
            <feather-icon icon="MehIcon" size="36" />
            <p class="mt-2 text-center w-75 text-muted">
              It seems like there are no data at the moment.
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
  </template>
  
  <script>
  import { BOverlay } from "bootstrap-vue";
  import ResponseMixins from "@/mixins/ResponseMixins";
  import VerticalBarChart from "../../../components/VerticalBarChart.vue";
  export default {
    components: { BOverlay, VerticalBarChart },
    mixins: [ResponseMixins],
  
    props: {
      labels:{
        type:Array,
        required:false
      },
      seriesData:{
        type:Array,
        required:false
      }
    },
  
    computed: {
      isEmpty(){
        return !this.series.some(item=>item)
      },
      series(){
        return this.seriesData.filter(item=>this.labels.includes(item.title)).map(item=>item.compliance)
      },
      options() {
        return {
          indexAxis: "y",
          title: {
            text: "Test",
            x: "center",
          },
          grid: {
            left: 0,
            right: 20,
            top: 100,
            bottom: 70,
            containLabel: true,
          },
          xAxis: [
            {
              type: "value",
            },
          ],
          yAxis: {
            type: "category",
            data: this.labels,
            splitLine: { show: false },
            nameLocation: "end",
          
          },
          series: [
            {
              type: "bar",
              barWidth: 40,
              data: this.series,
              itemStyle: {
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          ],
        };
      },
    },
  
    data() {
      return {
        showOverlay: false,
        statusCounts: null,
        // labels: null,
        // series: null,
      };
    },
  
  
    mounted() {
      // this.load();
    },
  
    methods: {
      // load() {
      //   // this.getDashboardThirdPartiesStatusesAndSetData();
  
      //   this.labels = [
      //     "Context of Organization",
      //     "Leadership",
      //     "Planning",
      //     "Support",
      //     "Operation",
      //     "Performance Evaluation",
      //     "aasdasdasd",
      //     "asdasdasdasd",
      //     "asdasdasdada",
      //   ];
      //   this.series = [54, 31, 63, 86, 54, 86,21, 67, 56];
      // },
  
      // getDashboardThirdPartiesStatusesAndSetData(params) {
      //   this.showOverlay = true;
      //   this.getDashboardThirdPartiesStatuses(params)
      //     .then((res) => {
      //       console.log(res);
      //       this.statusCounts = res.data.evidence_status_counts;
      //       this.labels = Object.keys(res.data.evidence_status_counts);
      //       this.series = Object.values(res.data.evidence_status_counts);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       this.handleError(err);
      //     })
      //     .finally(() => {
      //       this.showOverlay = false;
      //     });
      // },
  
      handleChartClick(val) {
        console.log("Chart Click ", val);
      },
    },
  };
  </script>
  
  <style></style>
  