<template>
  <div class="card my-0 border" style="width: 250px;">
    <div class="card-body p-1">
      <div
        class="w-100 d-flex align-items-center justify-content-between mb-25"
      >
        <h5 class="card-text font-weight-bold mb-0">{{ title }}</h5>

        <b-badge pill :variant="'light-'+progressVariant">{{ value }}%</b-badge>
      </div>
      <b-progress
        animated
        :value="value"
        :variant="progressVariant"
        :class="'progress-bar-primary' + progressVariant"
      />
    </div>
  </div>
</template>

<script>
import { BProgress, BBadge } from "bootstrap-vue";

export default {
  components: {
    BProgress,
    BBadge,
  },

  computed: {
    progressVariant() {
      const mappedValue = Math.floor(
        this.getMapedValue(this.value, 1, 100, 1, 5)
      );

      switch (mappedValue) {
        case 1:
          return this.colorsReversed ? "danger" : "success";
        case 2:
          return this.colorsReversed ? "warning" : "info";
        case 3:
          return "primary";
        case 4:
          return this.colorsReversed ? "info" : "warning";
        case 5:
          return this.colorsReversed ? "success" : "danger";
        default:
          return "secondary";
      }
    },
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    colorsReversed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    getMapedValue(value, in_min, in_max, out_min, out_max) {
      const newValue =
        ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
      return newValue;
    },
    
  },
};
</script>

<style></style>
