<template>
    <div class="w-100 h-auto">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 38%"
          >
          <!-- {{ updatedDashboardData }} -->
            <enitity-assessment-summary :summaryData="updatedDashboardData.entityAssessmentSummary"/>
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 32%"
          >
          <control-compliances :complianceData="updatedDashboardData.entityAssessmentSummary.govern_control_family_group.compliance" title="Govern System Compliance"/>

          <!-- <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
    
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 28%"
          >
          <implementation-of-clauses :clausesData="Object.values(updatedDashboardData.controlFamilySummary.govern_control_family_group)" title="Implementation Of Govern System Requirements"/>

    
          </div>
        </div>
    
        <!-- <div class="w-100 d-flex align-items-center justify-content-center mt-25">
          <h2 class="mb-1">Entity Assessment Overview</h2>
        </div> -->
    
        <div class="w-100 d-flex align-items-center justify-content-between" >
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 49%"
          >
          <control-compliances :complianceData="updatedDashboardData.entityAssessmentSummary.identify_control_family_group.compliance" title="Identify System Compliance"/>
    
            <!-- <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
          </div>
    
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 49%"
          >
          <implementation-of-clauses :clausesData="Object.values(updatedDashboardData.controlFamilySummary.identify_control_family_group)" title="Implementation Of Identify System Requirements"/>

    
            <!-- <annexure-controls-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.annex_control_family_group.compliance"/> -->
          </div>
        </div>
        <div class="w-100 d-flex align-items-center justify-content-between" >
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 49%"
          >
          <control-compliances :complianceData="updatedDashboardData.entityAssessmentSummary.protect_control_family_group.compliance" title="Protect System Compliance"/>

    
            <!-- <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
          </div>
    
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 49%"
          >
          <implementation-of-clauses :clausesData="Object.values(updatedDashboardData.controlFamilySummary.protect_control_family_group)" title="Implementation Of Protect System Requirements"/>

    
            <!-- <annexure-controls-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.annex_control_family_group.compliance"/> -->
          </div>
        </div>
        <div class="w-100 d-flex align-items-center justify-content-between" >
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 49%"
          >
          <control-compliances :complianceData="updatedDashboardData.entityAssessmentSummary.detect_control_family_group.compliance" title="Detect System Compliance"/>
    
            <!-- <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
          </div>
    
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 49%"
          >
          <implementation-of-clauses :clausesData="Object.values(updatedDashboardData.controlFamilySummary.detect_control_family_group)" title="Implementation Of Detect System Requirements"/>

    
            <!-- <annexure-controls-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.annex_control_family_group.compliance"/> -->
          </div>
        </div>
        <div class="w-100 d-flex align-items-center justify-content-between" >
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 49%"
          >
          <control-compliances :complianceData="updatedDashboardData.entityAssessmentSummary.respond_control_family_group.compliance" title="Respond System Compliance"/>
    
            <!-- <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
          </div>
    
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 49%"
          >
          <implementation-of-clauses :clausesData="Object.values(updatedDashboardData.controlFamilySummary.respond_control_family_group)" title="Implementation Of Respond System Requirements"/>

    
            <!-- <annexure-controls-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.annex_control_family_group.compliance"/> -->
          </div>
        </div>
        <div class="w-100 d-flex align-items-center justify-content-between" >
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 49%"
          >
          <control-compliances :complianceData="updatedDashboardData.entityAssessmentSummary.recover_control_family_group.compliance" title="Recover System Compliance"/>
    
            <!-- <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
          </div>
    
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 49%"
          >
          <implementation-of-clauses :clausesData="Object.values(updatedDashboardData.controlFamilySummary.recover_control_family_group)" title="Implementation Of Recover System Requirements"/>

    
            <!-- <annexure-controls-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.annex_control_family_group.compliance"/> -->
          </div>
        </div>
    
        <!-- <div class="w-100 d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 56%"
          >
            <implementation-of-management-system-requirements :seriesData="Object.values(updatedDashboardData.controlFamilySummary.clause_control_family_group)" />
          </div>
    
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 43%"
          >
            <implementation-of-annexture-control-requirements :seriesData="Object.values(updatedDashboardData.controlFamilySummary.annex_control_family_group)"  />
          </div>
        </div> -->
        <!-- <div class="w-100 d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 43%"
          >
            <ImplementationOfManagementRequirementByStatus :data="updatedDashboardData.controlFamilySummary.clause_control_family_group"/>
          </div>
    
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 56%"
          >
            <ImplementationOfAnnextureControlRequirementsByStatus :data="updatedDashboardData.controlFamilySummary.annex_control_family_group"/>
          </div>
        </div> -->
      </div>
    </template>
      <script>

import ControlCompliances from "./sections/ControlCompliances.vue";
import EnitityAssessmentSummary from "./sections/EnitityAssessmentSummary.vue";
import ImplementationOfClauses from "./sections/ImplementationOfClauses.vue";

    
      
      export default {
        components: {
          EnitityAssessmentSummary,
          ControlCompliances,
          ImplementationOfClauses

          
    
        },
        props:{
            updatedDashboardData:{
                type:Object,
                required:true
            }
        }
    
      };
      </script>
      
      <style></style>