<template>
    <div class="card priority-control-implementation">
        <div class="card-heading d-flex justify-content-center py-1">
            <h4>Priority {{ priority==='P1'?'1':priority==='P2'?'2':priority==='P3'?'3':'4' }} Controls Implementations</h4>
        </div>
        <div class="card-body table-wrapper">

            <b-table  :items="items" :fields="fields" bordered :sticky-header="'450px'" class="priority-table" head-variant="light">
            </b-table>
        </div>
    </div>
  </template>
  
  <script>
  import { BTable } from 'bootstrap-vue';
  export default {
    components:{
        BTable
    },
    props:{
      items:{
        type:Array,
        required:false
      },
      priority:{
        type:String,
        required:true
      }

    },
    data() {
      return {
        fields: [
          { key: 'control_no', label: 'Control' },
          { key: 'title', label: 'Control Name' },
          { key: 'implementation', label: 'Implementation' },
        ],
      };
    }
  };
  </script>
  
  <style >

.priority-table::-webkit-scrollbar {
  display: none;
  
}
.priority-table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* .priority-table::-webkit-scrollbar {
  width:1rem; 
}
.priority-table::-webkit-scrollbar-track {
  background-color: inherit
}
.priority-table::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100);
  border-radius: 1rem;
  border:0.25rem solid transparent;
  background-clip: content-box;
}

.priority-table::-webkit-scrollbar-button:increment,
.priority-table::-webkit-scrollbar-button:decrement {
  display: block !important;
} */
.custom-sticky-header .table-head {
  opacity: 1 !important;
}

  </style>
  