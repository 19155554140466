<template>
    <b-card class="w-100">
      <b-card-header>
        <h4 class="mb-0 text-center">Detailed Control Summary</h4>
      </b-card-header>
  
      <b-card-body>
        <!-- Tabs for control families -->
        <b-tabs v-model="selectedFamily" class="mb-3">
          <b-tab 
            v-for="(family, index) in controlFamilies" 
            :key="index" 
            :title="family.control_family.control_no"
            :title-link-class="'title-tooltip'"
          >
            <template #title>
              <span v-if="!showName" v-b-tooltip.hover :title="family.control_family.control_name">
                D {{ family.control_family.control_no }}
              </span>
              <span v-else v-b-tooltip.hover :title="family.control_family.control_name">
              {{ truncateText(family.control_family.control_name,30) }}
              </span>
            </template>
          </b-tab>
        </b-tabs>
  
        <!-- Table for the controls in the selected control family -->
        <b-table :items="selectedControls" :fields="fields" hover>
          <template #cell(compliance)="data">
            <span :class="{'text-success': data.value === 'Compliant', 'text-danger': data.value === 'Non Compliant'}">
              {{ data.value }}
            </span>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import { BCard, BCardHeader, BCardBody, BTable, BTabs, BTab, VBTooltip } from 'bootstrap-vue';
  
  export default {
    components: { BCard, BCardHeader, BCardBody, BTable, BTabs, BTab },
    directives: { 'b-tooltip': VBTooltip },
    
    props: {
      data: {
        type: Object,
        required: true
      },
      showName:{
        type:Boolean,
        required:false,
        default:false
      }
    },
    
    data() {
      return {
        selectedFamily: null,
      };
    },
    methods:{
      truncateText(text, length) {
        return text.length > length ? text.slice(0, length) + '...' : text;
      }
    },
    
    computed: {
      controlFamilies() {
        return Object.values(this.data);
      },
      selectedControls() {
        return this.selectedFamily !== null
          ? this.controlFamilies[this.selectedFamily].controls
          : [];
      },
      fields() {
        return [
          { key: 'control_no', label: 'Control No' },
          { key: 'control_name', label: 'Control Name' },
          { key: 'compliance', label: 'Compliance Status' },
        ];
      },
    },
    
    watch: {
      controlFamilies: {
        immediate: true,
        handler(families) {
          if (families.length > 0) {
            this.selectedFamily = 0;
          }
        }
      }
    }
  };
  </script>
  
  <style>
  .title-tooltip {
    cursor: pointer;
  }
  </style>
  