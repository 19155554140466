<template>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="dark"
      class="w-100"
    >
      <div class="card w-100">
        <div
          class="card-header d-flex flex-column align-items-center justify-content-center pb-0"
        >
          <h4 class="mb-0">
            Implementation Of Control By Domain
          </h4>
        </div>
  
        <div
          class="card-body d-flex align-items-center justify-content-center w-100"
          style="height: 600px"
        >
          <horizontal-bar-chart
            style="width: 100%;height:100%"
            :optionData="options"
            @chart-click="handleChartClick"
            v-if="!isEmpty"
          />
          <div
            v-else
            class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
          >
            <feather-icon icon="MehIcon" size="36" />
            <p class="mt-2 text-center w-75 text-muted">
              It seems like there are no data at the moment.
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
  </template>
  
  <script>
  import { BOverlay } from "bootstrap-vue";
  import ResponseMixins from "@/mixins/ResponseMixins";
  import HorizontalBarChart from "../../../components/HorizontalBarChart.vue";
  export default {
    components: { BOverlay, HorizontalBarChart },
    mixins: [ResponseMixins],
  
    props: {
      seriesData:{
        type:Array,
        required:false
      }
    },
  
    computed: {
      isEmpty(){
        return !this.series.some(item=>item)
      },
      series(){
        return this.seriesData.map(item=>item.compliance)
      },
      labels(){
        return this.seriesData.map(item=>item.title)
      },
      options() {
        return {
          indexAxis: "y",
          title: {
            text: "Test",
            x: "center",
          },
          grid: {
            left: 0,
            right: 20,
            top: 100,
            bottom: 70,
            containLabel: true,
          },
          xAxis: [
            {
              type: "value",
            },
          ],
          yAxis: {
            type: "category",
            data: this.labels,
            axisLabel:{
              formatter: function (value) {
                return value.length > 25 ? value.slice(0, 25) + "..." : value;
              },
            },
            splitLine: { show: false },
          },
          series: [
            {
              type: "bar",
              barWidth: 18,
              data: this.series,
              itemStyle: {
                // barBorderRadius: [0, 10, 10, 0],
              },
            },
          ],
        };
      },
    },
  
    data() {
      return {
        showOverlay: false,
        statusCounts: null,
        // labels: null,
        // series: null,
      };
    },
  
    mounted() {
      // this.load();
    },
  
    methods: {
      // load() {
      //   // this.getDashboardThirdPartiesStatusesAndSetData();
      //   this.labels = ["Technological", "Physical", "People", "Organizational"];
      //   this.series = [86, 21, 67, 56];
      // },
  
      // getDashboardThirdPartiesStatusesAndSetData(params) {
      //   this.showOverlay = true;
      //   this.getDashboardThirdPartiesStatuses(params)
      //     .then((res) => {
      //       console.log(res);
      //       this.statusCounts = res.data.evidence_status_counts;
      //       this.labels = Object.keys(res.data.evidence_status_counts);
      //       this.series = Object.values(res.data.evidence_status_counts);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       this.handleError(err);
      //     })
      //     .finally(() => {
      //       this.showOverlay = false;
      //     });
      // },
  
      handleChartClick(val) {
        const section = val.toLowerCase();
        let value = null;
        if (section == "rejected") {
          value = 4;
        } else if (section == "approved") {
          value = 3;
        } else if (section == "uploaded") {
          value = 2;
        } else if (section == "requested") {
          value = 1;
        }
        if (value != null) {
          // this.$router.push(`/responses?evidence_status=${value}`);
        }
      },
    },
  };
  </script>
  
  <style></style>
  