<template>
  <b-tabs>
    <!-- <b-tab title="General" lazy>
      <control-gap-assessment @gap-updated="recieveStandard" />
    </b-tab> -->
    <b-tab title="General(New)" lazy>
      <!-- <control-gap-assessment /> -->
      <general />
    </b-tab>
    <b-tab title="Management Summary" v-if="standard==='ISO27001'||standard==='ADHICS'||standard==='UAEIAR'||standard==='DESCISR'||standard==='SAMA'||standard==='NIST'" lazy>
       <management-summary :standardName="this.standard"/>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import ControlGapAssessment from "../ControlGapAssessment.vue";
import ManagementSummary from "./sections/ManagementSummary/ManagementSummary.vue";
import General from "./sections/General/General.vue";

export default {
  data(){
    return {
      standard:null
      // standard:'UAEIAR'
    }
  },
  components: {
    BTabs,
    BTab,
    ControlGapAssessment,
    ManagementSummary,
    General
  },
  methods:{
    recieveStandard(standard){ 
      this.standard=standard.name.toUpperCase().replace(/[\s_\-]/g, '')
    }
  }
};
</script>
<style></style>
