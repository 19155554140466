<template>
    <b-overlay
      :show="Object.values(loading).some(item=>item)"
      opacity="0.25"
      blur="1rem"
      rounded="md"
      variant="secondary"
    >

    <div v-if="standardName==='ISO27001'">
      <iso-summary :updatedDashboardData="updatedDashboardData" :loading="Object.values(loading).some(item=>item)"/>
    </div>
    <div v-else-if="standardName==='ADHICS'">
      <adhics-summary :updatedDashboardData="updatedDashboardData" :loading="Object.values(loading).some(item=>item)"/>
    </div>
    <div v-else-if="standardName==='UAEIAR'">
      <uae-ia-summary :updatedDashboardData="updatedDashboardData" :loading="Object.values(loading).some(item=>item)"/>
  
    </div>
    <div v-else-if="standardName==='DESCISR'">
      <desc-isr-summary :updatedDashboardData="updatedDashboardData" :loading="Object.values(loading).some(item=>item)"/>
  
    </div>
    <div v-else-if="standardName==='NIST'">
      <nist-summary :updatedDashboardData="updatedDashboardData" :loading="Object.values(loading).some(item=>item)"/>
  
    </div>
    <div v-else-if="standardName==='SAMA'">
      <sama-summary :updatedDashboardData="updatedDashboardData" :loading="Object.values(loading).some(item=>item)"/>
  
    </div>
    <div
              v-else
              class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
            >
              <feather-icon icon="MehIcon" size="36" />
              <p class="mt-2 text-center w-75 text-muted">
                It seems like there are no Data at the moment.
              </p>
            </div>
  </b-overlay>
  
</template>

<script>
import IsoSummary from "./IsoSummary.vue"
import AdhicsSummary from "./AdhicsSummary.vue"
import UaeIaSummary from "./UaeIaSummary.vue"
import DescIsrSummary from "./DescIsrSummary.vue";
import GapAssessmentMixins from "@/mixins/GapAssessmentMixins";
import { BOverlay } from "bootstrap-vue";
import SamaSummary from "./SamaSummary.vue";
import NistSummary from "./NistSummary.vue";

export default {
  components: {
    IsoSummary,
    AdhicsSummary,
    UaeIaSummary,
    DescIsrSummary,
    SamaSummary,
    NistSummary,
    BOverlay
  },
  data(){
    return {
      dashboardData:{},
      loading:{}

    }
  },
  props:{
    standardName:{
      type:String,
      required:true
    }
  },
  computed:{
    updatedDashboardData(){
      return this.roundDashboardData(this.dashboardData)
    },
    dashboardKeys(){
      if(this.standardName==='ISO27001'){
        return ['entityAssessmentSummary','controlFamilySummary','ImplementationByStatus']
      }
      else if(this.standardName==='ADHICS'){
        return ['entityAssessmentSummary','ImplementationOfControlByDomain','HieControlSummary']
      }
      else if(this.standardName==='UAEIAR'){
        return ['entityAssessmentSummary','ImplementationByStatus','ImplementationByStatusPriority','ImplementationByFamily','ImplementationByControl']
      }
      else if(this.standardName==='DESCISR'){
        return ['entityAssessmentSummary','ControlFamilySummary','ControlSummary']
      }
      else if(this.standardName==='SAMA'){
        return ['entityAssessmentSummary','ControlFamilySummary','ControlSummary']
      }
      else if(this.standardName==='NIST'){
        return ['entityAssessmentSummary','controlFamilySummary','ImplementationByStatus']
      }
      else []
    }
  },
  mixins:[GapAssessmentMixins],
  mounted(){
    this.setDashboardData()
  },
  methods:{
    roundToTwoPoints(value) {
      return Math.round(value * 100) / 100;
    },
    roundDashboardData(obj){
      if (obj === null || typeof obj !== 'object') {
        // If the value is not an object or array, return it (round if it's a number)
        return typeof obj === 'number' ? this.roundToTwoPoints(obj) : obj;
      }
      
      // Handle array or object
      const result = Array.isArray(obj) ? [] : {};
      
      // Process each key in the object or each item in the array
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          result[key] = this.roundDashboardData(obj[key]);
        }
      }
      return result;
    },
    setDashboardData(){
      this.dashboardKeys.forEach(async element => {
        try {
          this.$set(this.loading, element, true)
          let res
          if(element==='ImplementationByControl'){
            if (!this.dashboardData[element]) {
            this.$set(this.dashboardData, element, {});
            }
            const subsets=['P1','P2','P3','P4']
            subsets.forEach(async (sub)=>{

              res=await this.getGapAssessmentDashboard(this.$route.params.id,{widget_name:element,subset:sub})
              console.log(sub,res.data.data)
              this.$set(this.dashboardData[element], sub, res.data.data)
            })
            this.$set(this.loading, element, false)

          }
          else{
            res=await this.getGapAssessmentDashboard(this.$route.params.id,{widget_name:element})
            this.$set(this.dashboardData, element, res.data.data)
            this.$set(this.loading, element, false)

          }

        } catch (error) {
          console.log(error)
          this.$set(this.loading, element, false)
        }
      });
    }
  }
};
</script>

<style></style>
