<template>
    <div class="w-100 h-auto">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 43%"
          >
          <!-- {{ updatedDashboardData }} -->
            <enitity-assessment-summary :summaryData="convertEntity(updatedDashboardData.entityAssessmentSummary)"/>
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 55%"
          >
          <entity-assessment-compliance :complianceData="[updatedDashboardData.entityAssessmentSummary.true_controls_count,updatedDashboardData.entityAssessmentSummary.partial_contros_count,updatedDashboardData.entityAssessmentSummary.none_co0ntrols_count]"/>
          <!-- <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
    
          </div>
          <!-- <div
            class="d-flex align-items-center justify-content-center"
            style="width: 33%"
          >
          <annexure-controls-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.annex_control_family_group.compliance"/>
    
          </div> -->
        </div>
    
        <!-- <div class="w-100 d-flex align-items-center justify-content-center mt-25">
          <h2 class="mb-1">Entity Assessment Overview</h2>
        </div> -->
    
        <div class="w-100 d-flex align-items-center justify-content-between" >
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 100%"
          >
            <control-family-implementation :data="Object.values(updatedDashboardData.ControlFamilySummary)"/>
            <!-- <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
          </div>
    
          
        </div>
        <div class="w-100 d-flex align-items-center justify-content-between" >
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 100%"
          >
            <control-summary-implementation :data="Object.values(updatedDashboardData.ControlSummary)" :showName="true"/>
            <!-- <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
          </div>
    
          
        </div>
      </div>
    </template>
      <script>
      import EnitityAssessmentSummary from "./sections/EnitityAssessmentSummary.vue";
      import EntityAssessmentCompliance from "./sections/EntityAssessmentCompliance.vue";
      import ControlFamilyImplementation from "./sections/ControlFamilyImplementation.vue";
      import ControlSummaryImplementation from "./sections/ControlSummaryImplementation.vue";
    
    
      
      export default {
        components: {
          EnitityAssessmentSummary,
          EntityAssessmentCompliance,
          ControlFamilyImplementation,ControlSummaryImplementation
        },
        props:{
            updatedDashboardData:{
                type:Object,
                required:false
            }
        },
        methods:{
          convertEntity(obj) {
 
            if (obj) {
              return Object.keys(obj).reduce((acc, curr) => {
                console.log('curr',curr)
                if (curr === 'compliance') {
                  acc[curr] = {
                    title: 'Overall Implementation',
                    compliance: obj[curr]
                  };
                } else if (curr === 'none_co0ntrols_count') {
                  acc[curr] = {
                    title: 'Controls that are non-compliant',
                    compliance: obj[curr],
                    is_number:true
                  };
                } else if (curr === 'partial_contros_count') {
                  acc[curr] = {
                    title: 'Controls that are partially compliant',
                    compliance: obj[curr],
                    is_number:true
                  };
                } else if (curr === 'true_controls_count') {
                  acc[curr] = {
                    title: 'Controls that are fully compliant',
                    compliance: obj[curr],
                    is_number:true
                  };
                }
                return acc;
              }, {});
            }
            return {};
          }

        }
    
      };
      </script>
      
      <style></style>