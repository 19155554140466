<template>
  <div class="w-100 h-auto mb-2">
    <div
      class="w-100 d-flex flex-column align-items-start justify-content-start"
    >
      <div
        class="w-100 d-flex align-items-start justify-content-between cursor-pointer"
      >
        <div
          class="h-100 d-flex flex-column align-items-start justify-content-between pr-2"
          style="width: 65%"
        >
          <div
            class="d-flex flex-column align-items-start justify-content-start w-100"
          >
            <div class="w-100 d-flex align-items-center justify-content-start">
              <h4 class="mb-0">
                <b-badge pill variant="danger">{{ parent.control_no }}</b-badge>
              </h4>
              <b-button
                @click="
                  () => {
                    filtersVisible = !filtersVisible;
                  }
                "
                pill
                class="ml-1"
                size="sm"
                variant="outline-dark"
              >
                <feather-icon icon="FilterIcon" class="mr-50" />
                <span class="align-middle">Filters</span>
              </b-button>
            </div>
            <h5 class="mt-50 mb-0 font-weight-bolder">
              {{ parent.name }}
            </h5>
          </div>

          <!-- <b-collapse v-model="filtersVisible" class="w-100">
            <div class="app-fixed-search" style="width: 280px;">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    v-if="filters.search == null || filters.search == ''"
                    icon="SearchIcon"
                    class="text-muted"
                  />
                  <feather-icon
                    @click="
                      () => {
                        filters.search = null;
                      }
                    "
                    v-else
                    icon="XIcon"
                    class="text-danger cursor-pointer"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="filters.search"
                  placeholder="Search name..."
                  debounce="500"
                  size="sm"
                />
              </b-input-group>
            </div>
          </b-collapse> -->

          <div
            class="mt-0 py-50 d-flex align-items-center justify-content-between cursor-pointer"
            size="sm"
            @click="
              () => {
                controlsVisible = !controlsVisible;
              }
            "
          >
            <!-- <feather-icon
                  icon="SettingsIcon"
                  class="mr-50 text-primary"
                /> -->
            <span class="align-middle text-primary small font-weight-bold"
              >View Controls</span
            >
            <feather-icon
              :icon="controlsVisible ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              class="ml-50"
            />
          </div>
        </div>

        <div class="d-flex flex-column align-items-end justify-content-start">
          <div class="d-flex align-items-start justify-content-end w-100">
            <control-progress
              :value="parent.compliance"
              title="Compliance"
              description="Responses from all the below questions"
            />
            <div class="mr-75"></div>
            <control-progress
              :value="controlsOverallResponseProgressPercent.progressPercent"
              title="Progress"
              :description="`${controlsOverallResponseProgressPercent.validResponses}/${controlsOverallResponseProgressPercent.totalResponses} Questions Assessed`"
            />
          </div>
        </div>
      </div>

      <b-collapse v-model="controlsVisible" class="w-100">
        <div class="w-100 h-auto border rounded">
          <template v-for="control in controls">
            <control-item :key="control._id" :control="control" />
          </template>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  VBToggle,
  BCollapse,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import ControlProgress from "../../../components/ControlProgress.vue";
import ControlItem from "./ControlItem.vue";

export default {
  directives: {
    "b-toggle": VBToggle,
  },
  components: {
    BBadge,
    BCollapse,
    BButton,
    ControlProgress,
    ControlItem,
    ControlProgress,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },

  props: {
    controls: {
      type: Array,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      controlsVisible: true,
      filtersVisible: false,

      filters: {
        search: null,
      },
    };
  },

  computed: {
    controlsOverallResponseProgressPercent() {
      let totalResponses = 0;
      let validResponses = 0;

      // Iterate over each control
      this.controls.forEach((ctrl) => {
        // Count the number of valid responses for the current control
        const controlValidResponses = ctrl.responses.reduce((accu, res) => {
          if (res.response && res.response != null) {
            accu += 1;
          }
          return accu;
        }, 0);

        validResponses += controlValidResponses;
        totalResponses += ctrl.responses.length;
      });

      // Calculate the overall progress percentage
      const progressPercent = (validResponses / totalResponses) * 100;

      if (progressPercent % 1 !== 0) {
        return {
          progressPercent: parseInt(progressPercent.toFixed(2)),
          validResponses: validResponses,
          totalResponses: totalResponses,
        };
      }
      return {
        progressPercent: progressPercent,
        validResponses: validResponses,
        totalResponses: totalResponses,
      };
    },
  },
};
</script>

<style></style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;
  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    background-color: transparent;
  }
}
</style>
