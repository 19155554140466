<template>
  <div class="card my-0 border" style="width: 226px">
    <div class="card-body px-50 py-25">
      <div
        class="w-100 d-flex align-items-center justify-content-between"
      >
        <div
          style="width: 30%"
          class="d-flex align-items-center justify-content-center"
        >
          <vue-apex-charts
            type="radialBar"
            height="60"
            width="60"
            :options="chartData.options"
            :series="chartData.series"
          />
        </div>
        <div
          style="width: 67%"
          class="d-flex flex-column align-items-start justify-content-start"
        >
          <h6 class="mb-25 font-weight-bolder small">{{ title }}</h6>
          <small class="small">{{ description }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { $themeColors, $trackBgColor } from "@themeConfig";

export default {
  components: {
    VueApexCharts,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    colorsReversed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chartData: {
        series: [this.value],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "50%", // Increase hollow size to make bar thinner
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false,
                },
                value: {
                  show: true,
                  formatter: function (val) {
                    return `${val}%`;
                  },
                  fontSize: '11px',
                  fontWeight: 600,
                  offsetY: 5,
                },
              },
              stroke: {
                width: 4, // Decrease stroke width to make bar thinner
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
      chartColor: [
        $themeColors.primary,
        $themeColors.warning,
        $themeColors.secondary,
        $themeColors.info,
        $themeColors.danger,
      ],
    };
  },

  computed: {
    progressVariant() {
      const mappedValue = Math.floor(
        this.getMappedValue(this.value, 1, 100, 1, 5)
      );

      switch (mappedValue) {
        case 1:
          return this.colorsReversed ? "danger" : "success";
        case 2:
          return this.colorsReversed ? "warning" : "info";
        case 3:
          return "primary";
        case 4:
          return this.colorsReversed ? "info" : "warning";
        case 5:
          return this.colorsReversed ? "success" : "danger";
        default:
          return "secondary";
      }
    },
  },

  created() {
    this.updateChartData();
  },

  methods: {
    getMappedValue(value, in_min, in_max, out_min, out_max) {
      return (
        ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
      );
    },

    mapColorToValue(value) {
      if (value <= 11) {
        return this.colorsReversed ? $themeColors.success : $themeColors.danger;
      } else if (value > 11 && value <= 17) {
        return this.colorsReversed ? $themeColors.info : $themeColors.warning;
      } else if (value > 17 && value <= 35) {
        return $themeColors.success;
      } else if (value > 35 && value <= 74) {
        return this.colorsReversed ? $themeColors.warning : $themeColors.info;
      } else if (value > 74 && value <= 100) {
        return this.colorsReversed ? $themeColors.danger : $themeColors.success;
      } else {
        return $themeColors.primary;
      }
    },

    updateChartData() {
      const chartClone = JSON.parse(JSON.stringify(this.chartData));
      const color = this.mapColorToValue(this.value);
      chartClone.options.colors[0] = color;
      chartClone.options.plotOptions.radialBar.dataLabels.value.color = color;
      chartClone.series[0] = this.value;
      this.chartData = chartClone;
    },
  },

  watch: {
    value(newVal) {
      this.updateChartData();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  
}
.dark-layout {
  .card{
    background-color: #0e081c;
  }
}
</style>
