<template>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="dark"
      class="w-100"
    >
      <div class="card w-100" style="height: 400px;">
        <div class="card-header">
            <h4 class="mb-0 text-center">Technical Controls Implementation</h4>
        </div>
  
        <div class="card-body position-relative" style="height: 275px">
          <radial-chart
            :series="series"
            :legend="legend"
            @chart-click="handleChartClick"
            v-if="totalUsagePercent > 0"
          />
          <div
            v-else
            class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
          >
            <feather-icon icon="MehIcon" size="36" />
            <p class="mt-2 text-center w-75 text-muted">
              It seems like there are no data at the moment.
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
  </template>
  
  <script>
  import { BOverlay } from "bootstrap-vue";
  import RadialChart from "../../../components/RadialChart.vue";
  import ResponseMixins from '@/mixins/ResponseMixins';
  const chartColors = {
    primaryColorShade: "#836AF9",
    yellowColor: "#ffe800",
    successColorShade: "#28dac6",
    warningColorShade: "#ffe802",
    warningLightColor: "#FDAC34",
    infoColorShade: "#299AFF",
    greyColor: "#4F5D70",
    blueColor: "#2c9aff",
    blueLightColor: "#84D0FF",
    greyLightColor: "#EDF1F4",
    tooltipShadow: "rgba(0, 0, 0, 0.25)",
    lineChartPrimary: "#666ee8",
    lineChartDanger: "#ff4961",
    labelColor: "#6e6b7b",
    grid_line_color: "rgba(200, 200, 200, 0.2)",
  };
  const bgColors = [
    chartColors.primaryColorShade,
    chartColors.warningLightColor,
    chartColors.infoColorShade,
    chartColors.greyColor,
    chartColors.successColorShade,
  ];
  export default {
    mixins: [ResponseMixins],
    components: {
      BOverlay,
      RadialChart,
    },
    props:{
      complianceData:{
        type:Number,
        required:false
      }
    },
    data() {
      return {
        totalUsagePercent: 0,
        showOverlay: false,
  
        chartData: null,
  
        legend: {
          orient: "vertical",
          left: 0,
          top: "middle",
          formatter: function (name) {
            // Customize the legend text truncation
            const maxLength = 25; // Maximum length of legend text
            if (name.length > maxLength) {
              return name.substring(0, maxLength) + "..."; // Truncate text and add ellipsis
            }
            return name;
          },
        },
        series: [
          {
            name: "Risks",
            type: "pie",
            radius: ["0%", "75%"], // Set the inner and outer radius for the doughnut chart
            center: ["60%", "50%"],
            data: [
              // { value: 0, name: "Low", itemStyle: { color: "#4CAF50" } }, // Green color for "Low"
              // { value: 0, name: "Medium", itemStyle: { color: "#FFC107" } }, // Yellow color for "Medium"
              // { value: 0, name: "High", itemStyle: { color: "#FF5722" } }, // Orange color for "High"
              // { value: 0, name: "Critical", itemStyle: { color: "#F44336" } }, // Red color for "Critical"
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
  
    watch: {
     complianceData(){
      if(this.complianceData!==null&&this.complianceData!==undefined){
  
  this.totalUsagePercent = 2;
  
    const newSeriesData = [
      {
        value: 25,
        name: "Compliance",
        itemStyle: { color: bgColors[0] },
      },
      {
        value: 75,
        name: "Gap",
        itemStyle: { color: bgColors[1] },
      },
    ];
    this.$set(this.series[0], "data", newSeriesData);
  }
     }
    },
  
    mounted() {
      this.load();
    },
  
    methods: {
      load() {
      //   this.getDashboardTopPoliciesAndSetData(this.filters);
      if(this.complianceData!==null&&this.complianceData!==undefined){
  
        this.totalUsagePercent = 2;
    
          const newSeriesData = [
            {
              value: this.complianceData,
              name: "Implementation",
              itemStyle: { color: bgColors[0] },
            },
            {
              value: 100-this.complianceData,
              name: "Gap",
              itemStyle: { color: bgColors[1] },
            },
          ];
          this.$set(this.series[0], "data", newSeriesData);
      }
      },
  
      // getDashboardTopPoliciesAndSetData(params) {
      //   this.showOverlay = true;
      //   this.getDashboardTopPolicies(params)
      //     .then((res) => {
      //       console.log(res);
  
      //       const newSeriesData = res.data.top_policies.map((polc, i) => {
      //         this.totalUsagePercent += Number(polc.usage_percentage);
      //         return {
      //           value: polc.usage_percentage,
      //           name: polc.policy_name,
      //           itemStyle: { color: bgColors[i] },
      //         };
      //       });
      //       this.$set(this.series[0], "data", newSeriesData);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       this.handleError(err);
      //     })
      //     .finally(() => {
      //       this.showOverlay = false;
      //     });
      // },
  
      handleChartClick(section) {
        //   this.$router.push(`/policies`);
      },
  
      truncate(input) {
        if (input.length > 40) {
          return input.substring(0, 40) + "...";
        }
        return input;
      },
    },
  };
  </script>
  
  <style scoped>
  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 999; /* Ensure it's above other content */
  }
  </style>
  