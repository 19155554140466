<template>
  <div
    class="bg-transparent w-100 h-100 d-flex align-items-center justify-content-center"
  >
    <div
      class="d-flex flex-column align-items-center justify-content-center position-relative"
      style="padding: 3rem 0; width: 560px; min-height: 260px"
    >
      <b-badge variant="light-primary" pill style="width:3.6rem; height:3.6rem;" class="mb-1 d-flex align-items-center justify-content-center position-relative">
        <feather-icon icon="DatabaseIcon" style="width: 24px; height: 30px;" />
      </b-badge>
      <h4 class="font-weight-bolder"><b>{{ title }}</b></h4>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { BBadge } from "bootstrap-vue";
export default {
  components: { FeatherIcon, BBadge },
  props: {
    title: {
      type: String,
      required: false,
      default: "Empty Content",
    },
  },
};
</script>

<style></style>
