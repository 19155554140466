<template>
  <sidebar-template title="Question Assessment" :closeAction="closeAction">
    <template #content>
      <div class="w-100 h-auto position-relative">
        <b-overlay
          opacity="0.36"
          blur="5px"
          :show="showOverlay"
          rounded="md"
          variant="secondary"
          spinner-variant="primary"
          class="w-100"
          style="min-height: 100vh; position: fixed !important"
          no-wrap
        >
          <template #overlay>
            <div class="text-center">
              <b-icon
                icon="stopwatch"
                font-scale="2"
                animation="cylon"
              ></b-icon>
              <p class="pt-25 font-weight-bold" id="cancel-label">
                Please wait...
              </p>
            </div>
          </template>
        </b-overlay>
        <question-assessment-form
          :response="response"
          :reloadParent="load"
          :closeSidebar="closeAction"
          :setOverlay="setOverlay"
          :controlNo="controlNo"
        />
      </div>
    </template>
  </sidebar-template>
</template>

<script>
import { BOverlay, BIcon } from "bootstrap-vue";
import SidebarTemplate from "@/components/SidebarTemplate.vue";
import QuestionAssessmentForm from "./QuestionAssessmentForm.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import GapAssessmentMixins from "@/mixins/GapAssessmentMixins";
export default {
  mixins: [ResponseMixins, GapAssessmentMixins],
  components: {
    BOverlay,
    SidebarTemplate,
    QuestionAssessmentForm,
    BIcon,
  },

  props: {
    closeAction: {
      type: Function,
      required: true,
    },

    responseId: {
      type: String,
      required: true,
    },
    controlNo: {
      type: String,
      required: true,
    },
  },
  inject: ["responseSet"],

  data() {
    return {
      showOverlay: false,
      response: {},
    };
  },

  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getAssessmentResponseDetailsAndSetData(
        this.$route.params.id,
        this.responseId
      );
    },

    getAssessmentResponseDetailsAndSetData(assessmentId, responseId) {
      this.setOverlay(true);
      this.getGapAssessmentResponseDetails(assessmentId, responseId)
        .then((res) => {
          console.log("Response Set", this.responseSet);
          let newResponse = res.data.data;
          if (typeof newResponse.response != "undefined") {
            if (this.responseSet && this.responseSet !== null) {
              let newOptions = this.responseSet.map((option) => {
                let newOption = {
                  value: option.value,
                  text: option.title,
                };
                return newOption;
              });
              if (newResponse.question.always_applicable != "1") {
                newOptions.push({
                  value: -1,
                  text: "Not Applicable",
                });
              }
              newOptions.push({
                value: null,
                text: "--Select An Option--",
              });
              newResponse["options"] = newOptions;
            }
          }
          this.response = newResponse;
          console.log("Response Details", this.response);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.setOverlay(false);
        });
    },

    setOverlay(value) {
      this.showOverlay = value;
    },
  },
};
</script>

<style></style>
