<template>
  <div
    class="d-flex flex-column align-items-start justify-content-start w-100 pl-75 p-1 mb-1"
  >
    <div
      class="w-100 d-flex align-items-start justify-content-start mb-0 cursor-pointer"
      @click="
        () => {
          questionsVisible = !questionsVisible;
        }
      "
    >
      <div
        class="d-flex flex-column align-items-start justify-content-start w-100"
      >
        <div class="d-flex flex-column align-items-start justify-content-start" style="width: 75%">
          <div
            class="d-flex align-items-center justify-content-start w-100 mb-25"
          >
            <b-badge size="sm" pill variant="primary">{{
              control.control_no
            }}</b-badge>
            <div class="ml-2" style="width: 25%">
              <control-item-progress
                :max="100"
                :min="0"
                :value="controlResponseProgressPercent"
                title="Progress"
                :colorsReversed="true"
              />
            </div>
          </div>
          <h5 class="mb-0">
            {{ control.name }}
          </h5>
        </div>
        <div class="w-100 d-flex align-items-center justify-content-start">
          <div
            class="mt-0 py-50 d-flex align-items-center justify-content-between cursor-pointer"
            size="sm"
          >
            <!-- <feather-icon
                  icon="SettingsIcon"
                  class="mr-50 text-primary"
                /> -->
            <span class="align-middle text-primary small font-weight-bold"
              >View Questions</span
            >
            <feather-icon
              :icon="questionsVisible ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              class="ml-50"
            />
          </div>
        </div>
      </div>
    </div>

    <b-collapse v-model="questionsVisible" class="w-100">
      <div class="w-100 pl-1">
        <template v-for="(question, i) in control.responses">
          <question-item
            :key="question._id"
            :index="i + 1"
            :question="question"
            :controlNo="control.control_no"
          />
        </template>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BBadge, BCollapse, VBToggle } from "bootstrap-vue";
import ProgressBadge from "@/components/ProgressBadge.vue";
import ControlItemProgress from "../../../components/ControlItemProgress.vue";
import QuestionItem from "./QuestionItem.vue";
export default {
  components: {
    BBadge,
    BCollapse,
    ProgressBadge,
    ControlItemProgress,
    QuestionItem,
  },
  directives: {
    "b-toggle": VBToggle,
  },

  computed: {
    controlResponseProgressPercent() {
      // Count the number of valid responses
      const validResponses = this.control.responses.reduce((accu, res) => {
        if (res.response && res.response != null) {
          accu += 1;
        }
        return accu;
      }, 0);

      // Calculate the percentage of valid responses
      const totalResponses = this.control.responses.length;
      const progressPercent = (validResponses / totalResponses) * 100;

      return progressPercent;
    },
  },

  props: {
    control: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      questionsVisible: true,
    };
  },
};
</script>

<style></style>
