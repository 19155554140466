<template>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="dark"
      class="w-100"
    >
      <div class="card w-100" style="height: 380px">
        <div
          class="card-header d-flex flex-column align-items-center justify-content-center pb-0"
        >
          <h4 class="mb-0">
            Implementation By Control Domain
          </h4>
        </div>
        <div
          class="card-body d-flex align-items-center justify-content-center w-100 m-1"
        >
          <vertical-bar-chart
            style="width: 100%;"
            :optionData="options"
            @chart-click="handleChartClick"
            v-if="!isEmpty"
          />
          <div
            v-else
            class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
          >
            <feather-icon icon="MehIcon" size="36" />
            <p class="mt-2 text-center w-75 text-muted">
              It seems like there are no data at the moment.
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
</template>

<script>
import { BOverlay } from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import VerticalBarChart from "../../../components/VerticalBarChart.vue";

export default {
  components: { BOverlay, VerticalBarChart },
  mixins: [ResponseMixins],

  props: {
    data: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  computed: {
    isEmpty() {
      const trueControls = this.data.map(item => item.true_controls);
      const noneControls = this.data.map(item => item.none_controls);
      const partialControls = this.data.map(item => item.partial_controls);

      const allValues = [...trueControls, ...noneControls, ...partialControls];
      const hasNonZero = allValues.some(value => value > 0);

      return this.data.length === 0 || !hasNonZero;
    },
    labels() {
      return this.data.map(item => item.title);
    },
    series() {
      // Structure the data for the chart
      const trueControls = this.data.map(item => item.true_controls);
      const noneControls = this.data.map(item => item.none_controls);
      const partialControls = this.data.map(item => item.partial_controls);

      return [
        {
          name: "Full Compliance",
          type: "bar",
          data: trueControls,
          barWidth:'20%',
          barGap: '0%',
          barCategoryGap: '30%',
        },
        {
          name: "Non Compliance",
          type: "bar",
          data: noneControls,
          barWidth:'20%',
          barGap: '0%', 
          barCategoryGap: '30%',
        },
        {
          name: "Partial Compliance",
          type: "bar",
          data: partialControls,
          barWidth:'20%',
          barGap: '0%',
          barCategoryGap: '30%',
        }
      ];
    },
    options() {
      return {
        indexAxis: "y",
        title: {
          text: "Controls by Category",
          x: "center",
        },
        legend: { top: 50 },
        grid: {
          left: 0,
          right: 20,
          top: 100,
          bottom: 70,
          containLabel: true,
        },
        xAxis: [
          {
            type: "value",
          },
        ],
        yAxis: {
          type: "category",
          data: this.labels,
        },
        series: this.series
      };
    }
  },

  data() {
    return {
      showOverlay: false
    };
  },

  methods: {
    handleChartClick(val) {
      console.log("Chart Click ", val);
    }
  }
};
</script>

<style>
/* Add any custom styles here */
</style>
