<template>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="dark"
      class="w-100"
    >
      <div class="card w-100" >
        <div
          class="card-header d-flex flex-column align-items-center justify-content-center pb-0"
        >
          <h4 class="mb-1">
            Implementation By Family
          </h4>
        </div>
  
        <div
          class="card-body d-flex align-items-center justify-content-center w-100"
          
          
        >
          <b-table-simple :sticky-header="'750px'"
          class="family-table"
            v-if="Object.values(items).some(item=>item.length)"
          >
          <b-thead class="sticky-header">
            <b-th></b-th>
            <b-th>control Family</b-th>
            <b-th>Implementation %</b-th>
            <!-- <b-th>Deadlines</b-th> -->
          </b-thead>
          <b-tbody>
            <template v-for="categoryItem in Object.keys(updatedItems)" >
              <template v-for="(item,index) in updatedItems[categoryItem]" >
                <b-tr v-if="index" :key="`${categoryItem}${index}`">
                  <b-td>{{ item.title }}</b-td>
                  <b-td>{{ item.implementation }}</b-td>
                  <!-- <b-td>{{ item.deadlines }}</b-td> -->
                </b-tr>
                <b-tr v-else :key="`${categoryItem}_${index}`">
                  <b-th class="border" :rowspan="`${updatedItems[categoryItem].length}`">{{ categoryItem==='management_group'?'Management':'Technical' }}</b-th>
                  <b-td>{{ item.title }}</b-td>
                  <b-td>{{ item.implementation }}</b-td>
                  <!-- <b-td>{{ item.deadlines }}</b-td> -->
                </b-tr>
              </template>
            </template>
          </b-tbody>
          
          </b-table-simple>
          <div
            v-else
            class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
          >
            <feather-icon icon="MehIcon" size="36" />
            <p class="mt-2 text-center w-75 text-muted">
              It seems like there are no data at the moment.
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
  </template>
  
  
  <script>
import {
    BTable,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTd,
    BTbody

} from 'bootstrap-vue'
  
  export default {

    components: {
      BTable,
      BTableSimple,
      BThead,
      BTd,
      BTh,
      BTr,
      BTbody

    },
    props:{
      items:{
        type:Object,
        required:false,
        default:{'':[]}
      }
    },
    data() {
    return {

      
    };
  },
  computed:{
    updatedItems() {
        const obj=this.items
        Object.keys(obj).forEach(key => {
          if (!Array.isArray(obj[key])) {
            delete obj[key];
          }
        });
        return obj;
      }
  }
  };
  </script>
  
  <style scoped>
  .sticky-header {
    position: sticky;
    top: 0;
  }
  .family-table::-webkit-scrollbar {
  display: none;
}
.family-table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
  </style>
  