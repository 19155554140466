<template>
<div class="w-100 h-auto">
    <div class="w-100 d-flex align-items-center justify-content-between">
      <div
        class="d-flex align-items-center justify-content-center"
        style="width: 33%"
      >
      <!-- {{ updatedDashboardData }} -->
        <enitity-assessment-summary :summaryData="updatedDashboardData.entityAssessmentSummary"/>
      </div>
      <div
        class="d-flex align-items-center justify-content-center"
        style="width: 33%"
      >
      <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" />

      </div>
      <div
        class="d-flex align-items-center justify-content-center"
        style="width: 33%"
      >
      <annexure-controls-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.annex_control_family_group.compliance"/>

      </div>
    </div>

    <!-- <div class="w-100 d-flex align-items-center justify-content-center mt-25">
      <h2 class="mb-1">Entity Assessment Overview</h2>
    </div> -->

    <div class="w-100 d-flex align-items-center justify-content-between" >
      <div
        class="d-flex align-items-center justify-content-center"
        style="width: 49%"
      >
      <implementation-of-management-requirement-by-clauses :clausesData="Object.values(updatedDashboardData.controlFamilySummary.clause_control_family_group)" />

        <!-- <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
      </div>

      <div
        class="d-flex align-items-center justify-content-center"
        style="width: 49%"
      >
      <implementation-of-annexture-control-requirements-by-domain :clausesData="Object.values(updatedDashboardData.controlFamilySummary.annex_control_family_group)" />

        <!-- <annexure-controls-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.annex_control_family_group.compliance"/> -->
      </div>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-between">
      <div
        class="d-flex align-items-center justify-content-center"
        style="width: 56%"
      >
        <implementation-of-management-system-requirements :seriesData="Object.values(updatedDashboardData.controlFamilySummary.clause_control_family_group)" />
      </div>

      <div
        class="d-flex align-items-center justify-content-center"
        style="width: 43%"
      >
        <implementation-of-annexture-control-requirements :seriesData="Object.values(updatedDashboardData.controlFamilySummary.annex_control_family_group)"  />
      </div>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-between">
      <div
        class="d-flex align-items-center justify-content-center"
        style="width: 43%"
      >
        <ImplementationOfManagementRequirementByStatus :data="updatedDashboardData.ImplementationByStatus.clause_control_family_group"/>
      </div>

      <div
        class="d-flex align-items-center justify-content-center"
        style="width: 56%"
      >
        <ImplementationOfAnnextureControlRequirementsByStatus :data="updatedDashboardData.ImplementationByStatus.annex_control_family_group"/>
      </div>
    </div>
  </div>
</template>
  <script>
  import AnnexureControlsCompliance from "./sections/AnnexureControlsCompliance.vue";
  import EnitityAssessmentSummary from "./sections/EnitityAssessmentSummary.vue";
  import ImplementationOfAnnextureControlRequirements from "./sections/ImplementationOfAnnextureControlRequirements.vue";
  import ImplementationOfAnnextureControlRequirementsByDomain from "./sections/ImplementationOfAnnextureControlRequirementsByDomain.vue";
  import ImplementationOfManagementRequirementByClauses from "./sections/ImplementationOfManagementRequirementByClauses.vue";
  import ImplementationOfManagementSystemRequirements from "./sections/ImplementationOfManagementSystemRequirements.vue";
  import ManagementSystemCompliance from "./sections/ManagementSystemCompliance.vue";
  import ImplementationOfManagementRequirementByStatus from "./sections/ImplementationOfManagementRequirementByStatus.vue";
  import ImplementationOfAnnextureControlRequirementsByStatus from "./sections/ImplementationOfAnnextureControlRequirementsByStatus.vue";


  
  export default {
    components: {
      EnitityAssessmentSummary,
      ImplementationOfManagementRequirementByClauses,
      ImplementationOfAnnextureControlRequirementsByDomain,
      ManagementSystemCompliance,
      AnnexureControlsCompliance,
      ImplementationOfManagementSystemRequirements,
      ImplementationOfAnnextureControlRequirements,
      ImplementationOfManagementRequirementByStatus,
      ImplementationOfAnnextureControlRequirementsByStatus,

    },
    props:{
        updatedDashboardData:{
            type:Object,
            required:false
        }
    }

  };
  </script>
  
  <style></style>