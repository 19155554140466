<template>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="dark"
      class="w-100"
    >
      <div class="card w-100" style="height: 380px">
        <div
          class="card-header d-flex flex-column align-items-center justify-content-center pb-0"
        >
          <h4 class="mb-0">
            Implementation By Priority
          </h4>
        </div>
  
        <div
          class="card-body d-flex align-items-center justify-content-center w-100 m-1"
          
        >
          <vertical-bar-chart
            style="width: 100%;"
            :optionData="options"
            @chart-click="handleChartClick"
           v-if="!isEmpty"
          />
          <div
            v-else
            class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
          >
            <feather-icon icon="MehIcon" size="36" />
            <p class="mt-2 text-center w-75 text-muted">
              It seems like there are no data at the moment.
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
  </template>
  
  <script>
  import { BOverlay } from "bootstrap-vue";
  import ResponseMixins from "@/mixins/ResponseMixins";
  import VerticalBarChart from "../../../components/VerticalBarChart.vue";
  export default {
    components: { BOverlay, VerticalBarChart },
    mixins: [ResponseMixins],
  
    props: {
      data:{
        type:Object,
        required:false,
        default:{}
      }
    },
  
    computed: {
    isEmpty(){
      return !this.series?.some(seriesItem=>seriesItem?.data?.some(item=>item))
    },
    labels(){
        return Object.keys(this.data).map(item=>{
          if(item==='P1_control'){
            return 'Priority 1'
          }
          else if(item==='P2_control')
            return 'Priority 2'
          else if(item==='P3_control')
            return 'Priority 3'
          else 
            return 'Priority 4'
        })
    },
      series(){
        return Object.values(this.data).reduce((acc,curr)=>{
          Object.values(curr).forEach(item=>{
            
            if(acc.some((Accitem,index)=>{
              if(Accitem.name===item.title){
                acc[index].data.push(item.implementation)
                return true

              }
              else return false
            })){
            }
            else{
              acc.push({
              barWidth:'20%',
              type: "bar",
              name:item.title,
              data: [item.implementation],
              barGap: '0%',

              
            })
            }
          })
          return acc
        },[])
      },
      options() {
        return {
          indexAxis: "y",
          title: {
            text: "Test",
            x: "center",
          },
          legend:{top:50},
          grid: {
            left: 0,
            right: 20,
            top: 100,
            bottom: 70,
            containLabel: true,
          },
          xAxis: [
            {
              type: "value",
            },
          ],
          yAxis: {
            type: "category",
            data: this.labels,
            
          
          },
          series: this.series
        };
      },
    },
  
    data() {
      return {
        showOverlay: false,
        statusCounts: null,
        // labels: null,
        // series: null,
      };
    },
  
  
    mounted() {
      // this.load();
    },
  
    methods: {
      // load() {
      //   // this.getDashboardThirdPartiesStatusesAndSetData();
  
      //   this.labels = [
      //     "Context of Organization",
      //     "Leadership",
      //     "Planning",
      //     "Support"
      //   ];
      //   this.series = [54, 31, 63,89,9,34,12,0,1,2,3,4,5,6];
      // },
  
      // getDashboardThirdPartiesStatusesAndSetData(params) {
      //   this.showOverlay = true;
      //   this.getDashboardThirdPartiesStatuses(params)
      //     .then((res) => {
      //       console.log(res);
      //       this.statusCounts = res.data.evidence_status_counts;
      //       this.labels = Object.keys(res.data.evidence_status_counts);
      //       this.series = Object.values(res.data.evidence_status_counts);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       this.handleError(err);
      //     })
      //     .finally(() => {
      //       this.showOverlay = false;
      //     });
      // },
  
      handleChartClick(val) {
        console.log("Chart Click ", val);
      },
    },
  };
  </script>
  
  <style></style>
  