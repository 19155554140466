<template>
    <div class="w-100 h-auto">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 33%"
          >
          <!-- {{  updatedDashboardData}} -->
          <!-- {{ updatedDashboardData }} -->
            <enitity-assessment-summary :summaryData="updatedDashboardData.entityAssessmentSummary"/>
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 33%"
          >
            <ImplemntationOfControlByDomain :clausesData="Object.values(updatedDashboardData.ImplementationOfControlByDomain)" />
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 33%"
          >
          <hie-control-summary :summaryData="Object.values(updatedDashboardData.HieControlSummary)"/>
          </div>
        </div>
    
        <div class="w-100 d-flex align-items-center justify-content-center mt-25">
          <h2 class="mb-1">Entity Assessment Overview</h2>
        </div>
    
        <div class="w-100 d-flex align-items-center justify-content-between" >
            <div
            class="d-flex align-items-center justify-content-center"
            style="width: 33%"
          >
          <ImplementationByControlCategory :seriesData="Object.values(updatedDashboardData.entityAssessmentSummary)" :labels="['Basic Control Implementation','Transition Control Implementati','Advance Control Implementation']"/>
            <!-- <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 33%"
          >
          <overall-control-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.over_all_compliance.compliance"/>
            <!-- <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
          </div>
    
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 33%"
          >
          <hie-control-compliance :complianceData="updatedDashboardData.HieControlSummary.basic_control_family_group.compliance"/>
            <!-- <annexure-controls-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.annex_control_family_group.compliance"/> -->
          </div>
        </div>
    
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 100%"
          >
          <ImplementationOfControlByDomainBar :seriesData="Object.values(updatedDashboardData.ImplementationOfControlByDomain)"/>
            <!-- <implementation-of-management-system-requirements :seriesData="Object.values(updatedDashboardData.controlFamilySummary.clause_control_family_group)" :labels="['Context of the Organization','Leadership','Planning','Support','Operation','Performance Evaluation','Improvement']"/> -->
          </div>
    
         
        </div>
      </div>
    </template>
      <script>
      import EnitityAssessmentSummary from "./sections/EnitityAssessmentSummary.vue";
      import ImplementationByControlCategory from "./sections/ImplementationByControlCategory.vue"
      import HieControlSummary from "./sections/HieControlSummary.vue";
      import HieControlCompliance from './sections/HieControlCompliance.vue';
      import ImplemntationOfControlByDomain from './sections/ImplemntationOfControlByDomain.vue';
      import OverallControlCompliance from "./sections/OverallControlCompliance.vue";
      import ImplementationOfControlByDomainBar from "./sections/ImplementationOfControlByDomainBar.vue"
    
    
    
      
      export default {
        components: {
          EnitityAssessmentSummary,
          HieControlSummary,
          ImplemntationOfControlByDomain,
          ImplementationByControlCategory,
          HieControlCompliance,
          OverallControlCompliance,
          ImplementationOfControlByDomainBar
    
        },
        props:{
            updatedDashboardData:{
                type:Object,
                required:false,
                default:{}

            },
        }
    
      };
      </script>
      
      <style></style>