<template>
  <div class="w-100 h-auto email-application">
    <b-sidebar
      id="question-assessment-sidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
    >
      <question-assessment-sidebar
        :closeAction="closeQuestionAssessmentSidebar"
        :responseId="selectedQuestionId"
        :controlNo="selectedControlNo"
      />
    </b-sidebar>
    <div class="content-area-wrapper">
      <div class="sidebar-left">
        <div class="sidebar">
          <div class="sidebar-content email-app-sidebar">
            <div class="email-app-menu">
              <div class="sidebar-menu-header border-bottom px-1 py-50">
                <h5 class="mb-25 font-weight-bold">Control Families</h5>
                <div class="app-fixed-search w-100">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon
                        v-if="
                          controlFamilyFilters.search == null ||
                          controlFamilyFilters.search == ''
                        "
                        icon="SearchIcon"
                        class="text-muted"
                      />
                      <feather-icon
                        @click="
                          () => {
                            $set(controlFamilyFilters, 'search', null);
                          }
                        "
                        v-else
                        icon="XIcon"
                        class="text-danger cursor-pointer"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="controlFamilyFilters.search"
                      placeholder="Search..."
                      debounce="500"
                      size="sm"
                    />
                  </b-input-group>
                </div>
              </div>
              <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="sidebar-menu-list scroll-area"
              >
                <div class="list-group cursor-pointer">
                  <template v-for="family in filteredControlFamilies">
                    <small
                      :key="family._id"
                      class="list-group-item list-group-item-action px-75"
                      :class="[
                        {
                          active: selectedControlFamilyId == family._id,
                        },
                      ]"
                      @click="handleControlFamilySelect(family._id)"
                    >
                      {{ family.name }}
                    </small>
                  </template>
                </div>
              </vue-perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <b-overlay
          opacity="0.16"
          blur="1rem"
          :show="showOverlay"
          rounded="md"
          variant="secondary"
          spinner-variant="primary"
          class="h-100"
        >
          <template #overlay>
            <div class="text-center">
              <b-icon
                icon="stopwatch"
                font-scale="2"
                animation="cylon"
              ></b-icon>
              <p class="pt-25 font-weight-bold" id="cancel-label">
                Please wait...
              </p>
            </div>
          </template>
          <div class="content-wrapper">
            <div class="content-body">
              <div style="height: inherit">
                <div class="email-app-list px-75 py-1">
                  <vue-perfect-scrollbar
                    v-if="showOverlay === false"
                    :settings="controlGroupScrollbarSettings"
                    class="controls-review-list scroll-area"
                  >
                    <template
                      v-if="
                        responses && responses != null && responses.length > 0
                      "
                    >
                      <template v-for="response in responses">
                        <controls-group
                          :key="response._id"
                          :parent="response.parent"
                          :controls="response.controls"
                        />
                      </template>
                    </template>
                    <template v-else>
                      <empty-section>
                        <template #content>
                          <div
                            class="w-100 d-flex flex-column align-items-center justify-content-center"
                          >
                            <p class="text-center mb-75">
                              It seems like there are no Data at the moment.
                            </p>
                            <b-button
                              @click="
                                () => {
                                  $router.push('/gapassessment');
                                }
                              "
                              variant="outline-success"
                              class="mt-0"
                              size="sm"
                              pill
                              ><span class="mr-50">Goto Gap Assessments</span
                              ><feather-icon icon="ArrowRightCircleIcon"
                            /></b-button>
                          </div>
                        </template>
                      </empty-section>
                    </template>
                  </vue-perfect-scrollbar>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BOverlay,
  BSidebar,
  BIcon,
  BButton,
} from "bootstrap-vue";
import ControlsGroup from "./sections/ControlsGroup.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import GapAssessmentMixins from "@/mixins/GapAssessmentMixins";
import QuestionAssessmentSidebar from "./sections/QuestionAssessmentSidebar.vue";
import EmptySection from "@/views/NewDashboard/sections/GeneralDashboard/components/EmptySection.vue";

export default {
  mixins: [ResponseMixins, GapAssessmentMixins],
  components: {
    VuePerfectScrollbar,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    ControlsGroup,
    BOverlay,
    BSidebar,
    BIcon,
    QuestionAssessmentSidebar,
    EmptySection,
    BButton,
  },

  props: {
    mainFilters: {
      type: Object,
      required: true,
    },
    controlFamilies: {
      type: Array,
      required: true,
    },
    responseSet: {
      type: Array,
      required: true,
    },

    gapTitle: {
      type: String,
      required: true,
    },

    standardName: {
      type: String,
      required: true,
    },

    gapUserResourcePermissions: {
      type: Object,
      required: true,
    },

    standardUpdateDate: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      isInitialLoad:true,
      selectedQuestionId: null,
      selectedControlNo: null,
      showOverlay: false,
      selectedControlFamilyId: null,
      controlGroupScrollbarSettings: {
        maxScrollbarLength: 150,
        minScrollbarLength: 60,
        swipeEasing: true,
        wheelPropagation: true,
        wheelSpeed: 1.1,
      },
      perfectScrollbarSettings: {
        minScrollbarLength: 30,
      },
      controlFamilyFilters: {
        search: null,
      },

      responses: [],
    };
  },

  computed: {
    filteredControlFamilies() {
      if (
        this.controlFamilyFilters.search &&
        this.controlFamilyFilters.search != null
      ) {
        const searchTerm = this.controlFamilyFilters.search.toLowerCase();
        const newFilteredControlFamilies = this.controlFamilies.filter((fam) =>
          fam.name.toLowerCase().includes(searchTerm)
        );
        return newFilteredControlFamilies;
      }
      return this.controlFamilies;
    },
  },

  watch: {
    mainFilters: {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if (
          this.controlFamilies &&
          this.controlFamilies != null &&
          this.controlFamilies[0] &&
          Object.keys(this.controlFamilies[0]).length > 0 &&
          this.controlFamilies[0]._id &&
          this.controlFamilies[0]._id != null &&
          !this.isInitialLoad
        ) {
          this.getGapAssessmentControlFamilyResponsesAndSetData(
            this.$route.params.id,
            { ...newValue, control_family_id: this.selectedControlFamilyId }
          );
        }
      },
    },
  },

  created() {
    if (
      this.controlFamilies &&
      this.controlFamilies != null &&
      this.controlFamilies[0] &&
      Object.keys(this.controlFamilies[0]).length > 0 &&
      this.controlFamilies[0]._id &&
      this.controlFamilies[0]._id != null
    ) {
      this.load(this.controlFamilies[0]._id);
    }
  },

  methods: {
    load(familyId) {
      this.handleControlFamilySelect(familyId);
    },

    handleControlFamilySelect(id) {
      this.selectedControlFamilyId = id;
      this.getGapAssessmentControlFamilyResponsesAndSetData(
        this.$route.params.id,
        { control_family_id: id }
      );
    },

    getGapAssessmentControlFamilyResponsesAndSetData(id, params) {
      this.showOverlay = true;
      this.getGapAssessmentControlFamilyResponses(id, params)
        .then((res) => {
          this.isInitialLoad = false
          this.responses = res.data.data.reduce((accu, r) => {
            if (r.type && r.type == "Group") {
              accu.push({
                parent: this.getNewResponseObject(structuredClone(r)),
                controls: this.getControlsFromGroup(r),
              });
              return accu;
            } else if (r.type && r.type == "Control") {
              accu.push({
                parent: this.getNewResponseObject(structuredClone(r)),
                controls: this.getSubControlsFromControl(r),
              });
              return accu;
            }
          }, []);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getControlsFromGroup(obj) {
      if (obj.controls && obj.controls != null) {
        return obj.controls.reduce((accu, ctrl) => {
          //Insterting the Conrol
          const newCtrl = structuredClone(ctrl);
          delete newCtrl.subcontrols;
          //--Checks if the responses of the controls are not empty
          if (
            newCtrl.responses &&
            newCtrl.responses != null &&
            newCtrl.responses.length > 0
          ) {
            accu.push(newCtrl);
          }

          // -------------

          if (ctrl.subcontrols && ctrl.subcontrols != null) {
            return accu.concat(
              ctrl.subcontrols.filter(
                (sctrl) =>
                  sctrl.responses &&
                  sctrl.responses != null &&
                  sctrl.responses.length > 0
              )
            );
          }
          return accu;
        }, []);
      }
      return [];
    },

    getSubControlsFromControl(obj) {
      if (obj.subcontrols && obj.subcontrols != null) {
        return obj.subcontrols.filter(
          (sctrl) =>
            sctrl.responses &&
            sctrl.responses != null &&
            sctrl.responses.length > 0
        );
      }
      return [];
    },

    getNewResponseObject(obj) {
      const newObj = obj;
      if (newObj.controls) {
        delete newObj.controls;
      }
      if (newObj.subcontrols) {
        delete newObj.subcontrols;
      }
      if (newObj.responses) {
        delete newObj.responses;
      }

      newObj["compliance"] = (() => {
        const num = 100 - (Number(newObj.gap) || 0);
        if (num % 1 !== 0) {
          return parseInt(num.toFixed(2));
        }
        return num;
      })();
      return newObj;
    },

    openQuestionAssessmentSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "question-assessment-sidebar");
    },

    closeQuestionAssessmentSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "question-assessment-sidebar");
    },

    handleQuestionClick(id, controlNo) {
      console.log(id);
      this.selectedQuestionId = id;
      this.selectedControlNo = controlNo;
      this.openQuestionAssessmentSidebar();
    },
  },

  provide() {
    return {
      responseSet: this.responseSet,
      handleQuestionClick: this.handleQuestionClick,
      gapTitle: this.gapTitle,
      standardName: this.standardName,
      gapUserResourcePermissions: this.gapUserResourcePermissions,
      reloadControls: () => {
        this.load(this.selectedControlFamilyId);
      },
      standardUpdateDate: this.standardUpdateDate,
      reloadParent: () => {
        this.load(this.selectedControlFamilyId);
      },
    };
  },
};
</script>

<style></style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
