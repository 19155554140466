<template>
        <div class="w-100 h-auto">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 30%"
          >
          <!-- {{  updatedDashboardData}} -->
          <!-- {{ updatedDashboardData }} -->
            <enitity-assessment-summary :summaryData="updatedDashboardData.entityAssessmentSummary"/>
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 28%"
          >
          <!-- {{  updatedDashboardData}} -->
          <!-- {{ updatedDashboardData }} -->
            <implementation-by-status :seriesData="Object.values(updatedDashboardData.ImplementationByStatus.total_control)"/>
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 40%"
          >
          <!-- {{  updatedDashboardData}} -->
          <!-- {{ updatedDashboardData }} -->
            <implementation-by-priority :data="updatedDashboardData.ImplementationByStatusPriority"/>
          </div>
        </div>
        <div class="w-100 d-flex align-items-center justify-content-between">
          
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 65%"
          >
          <!-- {{  updatedDashboardData}} -->
          <!-- {{ updatedDashboardData }} -->
            <implementation-by-family :items="updatedDashboardData.ImplementationByFamily"/>
          </div>
          <div
            class=""
            style="width: 33%"
          >
          <!-- {{  updatedDashboardData}} -->
          <!-- {{ updatedDashboardData }} -->
          <management-controls-implementation :complianceData="updatedDashboardData.ImplementationByFamily.management_group_implementation" />
          <technical-controls-implementation :complianceData="updatedDashboardData.ImplementationByFamily.technical_group_implementation" />
            
          </div>

        </div>
        <div class="row">
          <div class="col-6 mb-4" v-for="(item, index) in subsets" :key="index">
            <priority-control-implementation :items="updatedDashboardData.ImplementationByControl[item]" :priority="item"/>
          </div>
        </div>
      </div>
</template>
<script>
import EnitityAssessmentSummary from './sections/EnitityAssessmentSummary.vue'
import ImplementationByPriority from './sections/ImplementationByPriority.vue'
import ImplementationByStatus from './sections/ImplementationByStatus.vue';
import ImplementationByFamily from './sections/ImplementationByfamily.vue'
import ManagementControlsImplementation from './sections/ManagementControlsImplementation.vue';
import TechnicalControlsImplementation from './sections/TechnicalControlsImplementation.vue';
import PriorityControlImplementation from './sections/PriorityControlImplementation.vue'

export default {
    components:{
        EnitityAssessmentSummary,
        ImplementationByPriority,
        ImplementationByStatus,
        ImplementationByFamily,
        ManagementControlsImplementation,
        TechnicalControlsImplementation,
        PriorityControlImplementation
        
    },
    data(){
      return {
        subsets:['P1','P2','P3','P4']
      }
    },
    props:{
      updatedDashboardData:{
        type:Object,
        required:false,
      }
    },
    updated(){
      console.log('data',this.updatedDashboardData)
    }

}
</script>