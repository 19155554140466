<template>
  <div
    class="w-100 h-auto mt-25 mb-1 cursor-pointer"
    @click="handleQuestionClick(question._id, controlNo)"
  >
    <div
      class="d-flex flex-column align-items-start justify-content-start w-100"
    >
      <div class="w-100 d-flex align-items-center justify-content-start mb-25">
        <b-badge size="sm" pill variant="warning" class="mr-50">
          <feather-icon icon="HelpCircleIcon" class="mr-50" />
          <span class="align-middle">Question</span>
        </b-badge>
        <b-badge
          variant="light-warning"
          class="mx-50 align-middle"
          pill
          v-b-tooltip.hover.top.v-secondary
          :title="'Updated At ' + formatTimestamp(standard.updated_at)"
          v-if="showUpdateBadge(standardUpdateDate, question.updated_at)"
        >
          <feather-icon class="mr-25" icon="InfoIcon" />
          <span class="align-middle">Updated</span>
          <feather-icon
            class="text-danger cursor-pointer ml-75"
            icon="XIcon"
            @click="handleUpdatedBadgeClick(item)"
        /></b-badge>
      </div>

      <div class="w-100 d-flex align-item-center justify-content-start">
        <div style="width: 65%" class="mr-1">
          <p class="mb-0">
            <span class="font-weight-bolder">{{ index }}</span
            >.&nbsp; {{ question.question.question }}
          </p>
          <div
            v-if="
              question.tags && question.tags != null && question.tags.length > 0
            "
            class="mt-25 w-100 d-flex align-items-center justify-content-start"
            v-b-tooltip.hover.left.v-primary
            title="Tags"
          >
            <b-badge
              variant="light-primary"
              class="mr-50"
              v-for="tag in question.tags"
              :key="tag"
              >{{ tag }}</b-badge
            >
          </div>
        </div>

        <div
          class="d-flex align-items-center justify-content-end"
          style="width: 35%"
        >
          <div class="w-50 px-25 d-flex align-items-center justify-content-end">
            <template v-if="question.response !== null">
              <b-badge
                variant="primary"
                v-if="question.status == 3"
                class="align-middle"
              >
                <!-- <feather-icon icon="SlashIcon" class="mr-25" /> -->
                <span class="align-middle">Not Applicable</span>
              </b-badge>
              <b-badge variant="primary" v-else>{{
                getResponseValue(question.response_value)
              }}</b-badge>
            </template>
          </div>

          <div class="w-50 d-flex align-items-center justify-content-start">
            <template v-if="question.evidence_status !== null">
              <b-badge
                :variant="getStatusVariant(question.evidence_status)"
                class="ml-1"
                >{{ getStatusValue(question.evidence_status) }}</b-badge
              >
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BBadge, BButton, VBTooltip } from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import UtilsMixins from "@/mixins/UtilsMixins";
import GapAssessmentMixins from "@/mixins/GapAssessmentMixins";
import ResponseMixins from "@/mixins/ResponseMixins";

export default {
  mixins: [UtilsMixins, GapAssessmentMixins, ResponseMixins],
  components: {
    BBadge,
    BButton,
    FeatherIcon,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  props: {
    index: {
      type: Number,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
    controlNo: {
      type: String,
      required: true,
    },
  },

  computed: {},
  inject: [
    "responseSet",
    "handleQuestionClick",
    "reloadParent",
    "standardUpdateDate",
  ],

  methods: {
    showUpdateBadge(standardUpdatedTime, responseUpdatedTime) {
      if (
        standardUpdatedTime &&
        standardUpdatedTime != null &&
        responseUpdatedTime &&
        responseUpdatedTime != null &&
        new Date(standardUpdatedTime) > new Date(responseUpdatedTime)
      ) {
        return true;
      }
      return false;
    },

    handleUpdatedBadgeClick(response) {
      let obj = {
        response_value: response.response_value,
        dismiss_is_updated_flag: 1,
        gapAssessmentId: response.gap_assessment_id,
        gapAssessmentResponseId: response._id,
      };

      this.showOverlay = true;
      this.updateGapAssessmentResponses(obj)
        .then((res) => {
          this.updateGapAssessmentResponsesFromResponse(res);
          this.handleResponse(res);
          this.reloadParent();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getResponseValue(num) {
      if (num !== null || num == 0) {
        const setIndex = this.responseSet.findIndex((set) => set.value == num);
        if (setIndex != -1) {
          return this.responseSet[setIndex].title;
        }
      }
    },

    getStatusValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Evidence Requested";
          case 2:
            return "Uploaded";
          case 3:
            return "Evidence Approved";
          case 4:
            return "Rejected";
        }
      }
    },

    getStatusVariant(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "dark";
          case 2:
            return "light-info";
          case 3:
            return "light-success";
          case 4:
            return "light-danger";
        }
      }
    },
  },
};
</script>

<style></style>
